import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchCourseById, createModule, updateModule, fetchLessonsByModuleId, fetchModulesByCourseId } from '../supabaseClient';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Plus, ArrowLeft } from 'lucide-react';

function ModuleForm() {
  const [module, setModule] = useState({
    title: '',
    description: '',
    course_id: '',
    order_index: 0
  });
  const [course, setCourse] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { courseId, moduleId } = useParams();

  useEffect(() => {
    if (courseId) {
      fetchCourseData();
    }
    if (moduleId) {
      fetchModuleData();
    }
  }, [courseId, moduleId]);

  const fetchCourseData = async () => {
    try {
      const courseData = await fetchCourseById(courseId);
      setCourse(courseData);
    } catch (err) {
      console.error("Error fetching course:", err);
      setError("Failed to fetch course data");
    }
  };

  const fetchModuleData = async () => {
    try {
      const modules = await fetchModulesByCourseId(courseId);
      const currentModule = modules.find(m => m.id === moduleId);
      if (currentModule) {
        setModule(currentModule);
        const fetchedLessons = await fetchLessonsByModuleId(moduleId);
        setLessons(fetchedLessons);
      }
    } catch (err) {
      console.error("Error fetching module:", err);
      setError("Failed to fetch module data");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const moduleData = {
        ...module,
        course_id: courseId,
      };
      
      const data = moduleId
        ? await updateModule(moduleId, moduleData)
        : await createModule(moduleData);
      
      navigate(`/admin/course/${courseId}`);
    } catch (err) {
      console.error("Error saving module:", err);
      setError(err.message || 'An error occurred while saving the module');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 flex gap-4">
      <Card className="w-1/3">
        <CardHeader>
          <CardTitle>Course Information</CardTitle>
        </CardHeader>
        <CardContent>
          {course && (
            <>
              <h2 className="text-xl font-bold">{course.title}</h2>
              <p>{course.description}</p>
            </>
          )}
        </CardContent>
      </Card>
      <Card className="w-2/3">
        <CardHeader>
          <CardTitle>{moduleId ? 'Edit Module' : 'Add New Module'}</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <Input
              type="text"
              value={module.title}
              onChange={(e) => setModule({ ...module, title: e.target.value })}
              placeholder="Module Title"
            />
            <Textarea
              value={module.description}
              onChange={(e) => setModule({ ...module, description: e.target.value })}
              placeholder="Module Description"
            />
            <Button type="submit">Save Module</Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

export default ModuleForm;