import React, { useState } from 'react';
import { usePlaylist } from '../PlaylistContext';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { 
  DropdownMenu, 
  DropdownMenuContent, 
  DropdownMenuItem, 
  DropdownMenuTrigger,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuSeparator
} from "../components/ui/dropdown-menu";
import { Button } from "../components/ui/button";
import CreatePlaylistModal from './CreatePlaylistModal';

function TrackOptionsMenu({ track }) {
  const { playlistId } = useParams();
  const { playlists, addTrackToPlaylist, removeTrackFromPlaylist } = usePlaylist();
  const [isAdding, setIsAdding] = useState(false);
  const [isCreatePlaylistModalOpen, setIsCreatePlaylistModalOpen] = useState(false);

  const handleAddToPlaylist = async (selectedPlaylistId) => {
    if (track && track.id) {
      setIsAdding(true);
      try {
        await addTrackToPlaylist(selectedPlaylistId, track.id);
        toast.success('Track added to playlist successfully');
      } catch (error) {
        console.error('Error adding track to playlist:', error);
        toast.error('Failed to add track to playlist');
      } finally {
        setIsAdding(false);
      }
    } else {
      console.error('Track information is missing or invalid:', track);
      toast.error('Invalid track information');
    }
  };

  const handleRemoveFromPlaylist = async () => {
    if (playlistId && track && track.id) {
      try {
        await removeTrackFromPlaylist(playlistId, track.id);
        toast.success('Track removed from playlist successfully');
      } catch (error) {
        console.error('Error removing track from playlist:', error);
        toast.error('Failed to remove track from playlist');
      }
    } else {
      console.error('Playlist ID or track information is missing or invalid:', { playlistId, track });
      toast.error('Invalid playlist or track information');
    }
  };

  const handleCreateNewPlaylist = () => {
    setIsCreatePlaylistModalOpen(true);
  };

  if (!track || !track.id || !track.title) {
    console.error('Track information is missing or invalid:', track);
    return null;
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" disabled={isAdding}>...</Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onSelect={handleCreateNewPlaylist}>
            + Create New Playlist
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>Add to Playlist</DropdownMenuSubTrigger>
            <DropdownMenuSubContent>
              {playlists.map((playlist) => (
                <DropdownMenuItem 
                  key={playlist.id} 
                  onSelect={() => handleAddToPlaylist(playlist.id)}
                  disabled={isAdding}
                >
                  {playlist.emoji} {playlist.title}
                </DropdownMenuItem>
              ))}
            </DropdownMenuSubContent>
          </DropdownMenuSub>
          {playlistId && (
            <DropdownMenuItem onSelect={handleRemoveFromPlaylist} disabled={isAdding}>
              Remove from Playlist
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      <CreatePlaylistModal 
        isOpen={isCreatePlaylistModalOpen} 
        onClose={() => setIsCreatePlaylistModalOpen(false)}
        trackToAdd={track}
      />
    </>
  );
}

export default TrackOptionsMenu;