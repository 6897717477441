import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useOutletContext, useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { FaPlay, FaPause } from 'react-icons/fa';
import { FiHeart } from 'react-icons/fi';
import LikeLoadingSpinner from './LikeLoadingSpinner';
import { Button } from "../components/ui/button";
import TrackOptionsMenu from './TrackOptionsMenu';
import { checkCredits, deductCredits, CREDIT_COSTS } from '../utils/creditUtils';
import { Toaster } from 'react-hot-toast';

function Streaming() {
  const location = useLocation();
  const [albums, setAlbums] = useState({});
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const { 
    currentTrack, 
    setCurrentTrack, 
    isPlaying, 
    setIsPlaying,
    likedTracks,
    handleLikeClick,  // We'll use this directly
    loadingLikes,
    setLoadingLikes,
    isPremium,
    setCredits,
    credits  // Add this line
  } = useOutletContext();
  const [user, setUser] = useState(null);
  const [recentTracks, setRecentTracks] = useState([]);
  const navigate = useNavigate();
  const albumViewRef = useRef(null);
  const [hoveredTrack, setHoveredTrack] = useState(null);
  const [localPlayingTrack, setLocalPlayingTrack] = useState(null);

  const checkUser = useCallback(async () => {
    const { data: { user }, error } = await supabase.auth.getUser();
    if (error) {
      console.error('Error fetching user:', error);
      return null;
    }
    return user;
  }, []);

  const fetchAlbumTracks = useCallback(async (albumTitle) => {
    const { data, error } = await supabase
      .from('meditations')
      .select('id, title, audio_url, duration, album, artist, artwork_url')
      .eq('album', albumTitle)
      .order('created_at', { ascending: true });

    if (error) {
      console.error('Error fetching album tracks:', error);
      return null;
    }
    
    if (data && data.length > 0) {
      return {
        id: data[0].id,
        title: data[0].album,
        artist: data[0].artist,
        artwork_url: data[0].artwork_url,
        tracks: data.map(track => ({
          id: track.id,
          title: track.title,
          audio_url: track.audio_url,
          duration: track.duration
        }))
      };
    }
    return null;
  }, []);

  const fetchAlbums = useCallback(async () => {
    const { data, error } = await supabase
      .from('meditations')
      .select('id, album, artist, artwork_url, title, audio_url, duration, created_at, category')
      .order('album', { ascending: true })
      .order('created_at', { ascending: true });

    if (error) {
      console.error('Error fetching albums:', error);
    } else {
      const groupedAlbums = data.reduce((acc, item) => {
        if (!acc[item.album]) {
          acc[item.album] = {
            id: item.id,
            title: item.album,
            artist: item.artist,
            artwork_url: item.artwork_url,
            category: item.category,
            tracks: []
          };
        }
        acc[item.album].tracks.push({
          id: item.id,
          title: item.title,
          audio_url: item.audio_url,
          duration: item.duration,
          created_at: item.created_at
        });
        return acc;
      }, {});

      Object.values(groupedAlbums).forEach(album => {
        album.tracks.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      });

      const categorizedAlbums = Object.values(groupedAlbums).reduce((acc, album) => {
        if (!acc[album.category]) {
          acc[album.category] = [];
        }
        acc[album.category].push(album);
        return acc;
      }, {});

      setAlbums(categorizedAlbums);
    }
  }, []);

  const fetchRecentTracks = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('recently_played_tracks')
        .select('*')
        .eq('user_id', user.id)
        .order('played_at', { ascending: false });

      if (error) throw error;

      const uniqueTracks = data.reduce((acc, track) => {
        if (!acc[track.track_id] || new Date(track.played_at) > new Date(acc[track.track_id].played_at)) {
          acc[track.track_id] = track;
        }
        return acc;
      }, {});

      setRecentTracks(Object.values(uniqueTracks).slice(0, 5));
    } catch (error) {
      console.error('Error fetching recent tracks:', error);
    }
  }, [user]);

  useEffect(() => {
    async function initializeComponent() {
      const user = await checkUser();
      if (user) {
        setUser(user);
        await fetchAlbums();
        fetchRecentTracks();
      } else {
        navigate('/login');
      }
    }

    initializeComponent();
  }, [checkUser, fetchAlbums, fetchRecentTracks, navigate]);

  useEffect(() => {
    async function handleSelectedAlbum() {
      if (location.state && location.state.selectedAlbum) {
        const albumData = await fetchAlbumTracks(location.state.selectedAlbum);
        if (albumData) {
          setSelectedAlbum(albumData);
        }
        // Clear the location state to prevent persisting the selection on refresh
        window.history.replaceState({}, document.title);
      }
    }

    handleSelectedAlbum();
  }, [location, fetchAlbumTracks]);

  function handleAlbumClick(album) {
    setSelectedAlbum(album);
  }

  async function playPause(track) {
    if (currentTrack && currentTrack.id === track.id) {
      setIsPlaying(!isPlaying);
    } else {
      if (!isPremium) {
        const canStream = await checkCredits(user.id, 'stream');
        if (!canStream) return;

        const newCredits = await deductCredits(user.id, CREDIT_COSTS.STREAM_TRACK);
        if (newCredits === null) return;

        setCredits(newCredits);
      }

      try {
        let trackToPlay;
        if (track.audio_url) {
          trackToPlay = { ...track };
        } else {
          const { data, error } = await supabase
            .from('meditations')
            .select('*')
            .eq('id', track.track_id)
            .single();

          if (error) throw error;

          if (data) {
            trackToPlay = { ...track, ...data };
          } else {
            console.error('No matching track found in meditations table');
            return;
          }
        }

        // Ensure artwork_url, artist, and album are included
        if (!trackToPlay.artwork_url || !trackToPlay.artist || !trackToPlay.album) {
          let album;
          if (selectedAlbum) {
            album = selectedAlbum;
          } else {
            album = Object.values(albums).flat().find(a => a.tracks.some(t => t.id === trackToPlay.id));
          }

          if (album) {
            trackToPlay = {
              ...trackToPlay,
              artwork_url: trackToPlay.artwork_url || album.artwork_url,
              artist: trackToPlay.artist || album.artist,
              album: trackToPlay.album || album.title
            };
          }
        }

        console.log("Track to play:", trackToPlay);
        if (currentTrack && currentTrack.id === trackToPlay.id) {
          setIsPlaying(!isPlaying);
        } else {
          setCurrentTrack(trackToPlay);
          setIsPlaying(true);
        }
        setLocalPlayingTrack(trackToPlay);
        await addToRecentlyPlayed(trackToPlay);
      } catch (error) {
        console.error('Error playing track:', error);
      }
    }
  }

  async function addToRecentlyPlayed(track) {
    console.log('Adding track to recently played:', track);
    console.log('User premium status:', isPremium);
    console.log('Current credits:', credits);

    try {
      // Add track to recently played as before
      const { error } = await supabase
        .from('recently_played_tracks')
        .upsert({
          user_id: user.id,
          track_id: track.id || track.track_id,
          title: track.title,
          artist: track.artist,
          album: track.album,
          artwork_url: track.artwork_url,
          played_at: new Date().toISOString()
        }, {
          onConflict: 'user_id,track_id'
        });

      if (error) {
        console.error('Error adding track to recently played:', error);
        throw error;
      }

      console.log('Track added to recently played successfully');

      // Deduct credits if user is not premium
      if (!isPremium) {
        console.log('Attempting to deduct credits');
        const { data, error: creditError } = await supabase.rpc('deduct_credits', {
          user_id: user.id,
          amount_to_deduct: 5
        });

        if (creditError) {
          console.error('Error deducting credits:', creditError);
          throw creditError;
        }

        console.log('Credits deducted successfully. New balance:', data);

        // Update local credit state
        setCredits(data);
      } else {
        console.log('User is premium, no credits deducted');
      }

      fetchRecentTracks();
    } catch (error) {
      console.error('Error in addToRecentlyPlayed:', error);
    }
  }

  function formatDuration(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    
    const parts = [
      hours > 0 ? hours.toString().padStart(2, '0') : null,
      minutes.toString().padStart(2, '0'),
      remainingSeconds.toString().padStart(2, '0')
    ].filter(Boolean);
    
    return parts.join(':');
  }

  return (
    <div className="streaming">
      <Toaster />
      {!selectedAlbum && (
        <>
          <div className="recently-played-section mb-8">
            <h2 className="text-2xl font-bold mb-4">Recently Played</h2>
            <div className="flex overflow-x-auto pb-4 space-x-4">
            {recentTracks.length > 0 ? (
              recentTracks.map((track, index) => (
                <div 
                  key={index} 
                  className="flex-none w-64 bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transition-transform duration-300 hover:scale-105"
                  onClick={() => playPause(track)}
                  onMouseEnter={() => setHoveredTrack(track.id)}
                  onMouseLeave={() => setHoveredTrack(null)}
                >
                  <div className="relative aspect-square">
                    <img src={track.artwork_url} alt={`${track.album} cover`} className="w-full h-full object-cover" />
                    {hoveredTrack === track.id && (
                      <div className="absolute inset-0 flex items-center justify-center object-cover aspect-square bg-black bg-opacity-50">
                        <button className="text-white text-4xl">
                          {currentTrack && currentTrack.id === track.id && isPlaying ? <FaPause /> : <FaPlay />}
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="p-4">
                    <h3 className="font-semibold text-lg mb-1 truncate">{track.title}</h3>
                    <p className="text-gray-600 text-sm truncate">{track.artist}</p>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-gray-600">No recently played tracks.</p>
            )}
          </div>
          </div>
          {Object.entries(albums).map(([category, categoryAlbums]) => (
            <div key={category} className="mb-8">
              <h2 className="text-2xl font-bold mb-4">{category}</h2>
              <div className="flex overflow-x-auto pb-4 space-x-4">
                {categoryAlbums.map(album => (
                  <div 
                    key={album.id} 
                    className="flex-none w-64 bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transition-transform duration-300 hover:scale-105"
                    onClick={() => handleAlbumClick(album)}
                  >
                    <img src={album.artwork_url} alt={album.title} className="w-full h-48 object-cover" />
                    <div className="p-4">
                      <h3 className="font-semibold text-lg mb-1 truncate">{album.title}</h3>
                      <p className="text-gray-600 text-sm truncate">{album.artist}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </>
      )}
      {selectedAlbum && (
        <div ref={albumViewRef} className="bg-white rounded-lg shadow-lg p-8">
          <div className="flex flex-col md:flex-row items-center md:items-start mb-8">
            <img src={selectedAlbum.artwork_url} alt={selectedAlbum.title} className="w-64 h-64 object-cover aspect-square rounded-lg mb-4 md:mb-0 md:mr-8" />
            <div>
              <h1 className="text-3xl font-bold mb-2">{selectedAlbum.title}</h1>
              <h2 className="text-xl text-gray-600 mb-4">{selectedAlbum.artist}</h2>
              <Button 
                onClick={() => setSelectedAlbum(null)}
                variant="default"
              >
                Back to Albums
              </Button>
            </div>
          </div>
          <div className="space-y-4">
            {selectedAlbum.tracks && selectedAlbum.tracks.map(track => (
              <div key={track.id} className="flex items-center justify-between p-4 bg-gray-100 rounded-lg">
                <div className="flex-grow">
                  <h3 className="font-semibold">{track.title}</h3>
                  <p className="text-gray-600 text-sm">{selectedAlbum.artist}</p>
                </div>
                <div className="flex items-center space-x-4">
                  <span className="text-gray-500">{formatDuration(track.duration)}</span>
                  <button 
                    className={`like-button ${likedTracks[track.id] ? 'liked' : ''}`} 
                    onClick={(e) => {
                      e.stopPropagation();
                      handleLikeClick({
                        ...track,
                        artist: track.artist || selectedAlbum.artist,
                        album: track.album || selectedAlbum.title,
                        artwork_url: track.artwork_url || selectedAlbum.artwork_url,
                        audio_url: track.audio_url || selectedAlbum.artwork_url,
                      });
                    }}
                    disabled={loadingLikes[track.id]}
                  >
                    {loadingLikes[track.id] ? (
                      <LikeLoadingSpinner />
                    ) : (
                      <FiHeart className={`heart-icon ${likedTracks[track.id] ? 'liked' : ''}`} />
                    )}
                  </button>
                  <button 
                    className={`text-gray-600 hover:text-gray-800 focus:outline-none ${currentTrack && currentTrack.id === track.id && isPlaying ? 'text-blue-500' : ''}`}
                    onClick={() => playPause(track)}
                  >
                    {currentTrack && currentTrack.id === track.id && isPlaying ? <FaPause className="w-6 h-6" /> : <FaPlay className="w-6 h-6" />}
                  </button>
                  <TrackOptionsMenu track={track} />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Streaming;
