import { supabase } from '../supabaseClient';
import toast from 'react-hot-toast';

export const CREDIT_COSTS = {
  STREAM_TRACK: 5,
  CHAT_MESSAGE: 1
};

export async function checkCredits(userId, action) {
  try {
    const { data: subscription, error: subError } = await supabase
      .from('subscriptions')
      .select('status, current_period_end')
      .eq('user_id', userId)
      .maybeSingle();

    if (subError) {
      console.error('Error fetching subscription status:', subError);
      return false;
    }

    const isPremium = subscription?.status === 'active' && new Date(subscription.current_period_end) > new Date();

    if (isPremium) {
      return true; // Premium users don't need to check credits
    }

    const { data: credits, error: creditError } = await supabase
      .from('user_credits')
      .select('credits')
      .eq('user_id', userId)
      .single();

    if (creditError) {
      console.error('Error fetching user credits:', creditError);
      return false;
    }

    const requiredCredits = CREDIT_COSTS[action];

    if (credits.credits < requiredCredits) {
      toast.error(`Insufficient credits. You need ${requiredCredits} credits for this action.`);
      return false;
    }

    return true;
  } catch (error) {
    console.error('Error in checkCredits:', error);
    return false;
  }
}

export async function deductCredits(userId, amount) {
  const { data, error } = await supabase.rpc('deduct_credits', {
    user_id: userId,
    amount_to_deduct: amount
  });

  if (error) {
    console.error('Error deducting credits:', error);
    return null;
  }

  return data;
}
