import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchModulesByCourseId, createLesson, updateLesson, uploadLessonFile, fetchLessonsByModuleId } from '../supabaseClient';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Progress } from "./ui/progress";
import { X, Upload, ArrowLeft } from 'lucide-react';
import { toast } from 'react-hot-toast';

function LessonForm() {
  const [lesson, setLesson] = useState({
    title: '',
    content: '',
    module_id: '',
    order_index: 0,
    content_type: '',
    file_url: ''
  });
  const [module, setModule] = useState(null);
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();
  const { courseId, moduleId, lessonId } = useParams();

  useEffect(() => {
    if (moduleId) {
      fetchModuleData();
    }
    if (lessonId) {
      fetchLessonData();
    }
  }, [moduleId, lessonId]);

  const fetchModuleData = async () => {
    try {
      const modules = await fetchModulesByCourseId(courseId);
      const currentModule = modules.find(m => m.id === moduleId);
      setModule(currentModule);
    } catch (err) {
      console.error("Error fetching module:", err);
      setError("Failed to fetch module data");
    }
  };

  const fetchLessonData = async () => {
    try {
      const lessons = await fetchLessonsByModuleId(moduleId);
      const currentLesson = lessons.find(l => l.id === lessonId);
      if (currentLesson) {
        setLesson(currentLesson);
        if (currentLesson.file_url) {
          // Extract filename from URL
          const filename = currentLesson.file_url.split('/').pop();
          setFile({ name: filename, url: currentLesson.file_url });
        }
      }
    } catch (err) {
      console.error("Error fetching lesson:", err);
      setError("Failed to fetch lesson data");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsUploading(true);
    try {
      let fileUrl = lesson.file_url;
      let contentType = lesson.content_type;
      if (file && !file.url) {
        console.log("Uploading file...");
        fileUrl = await uploadLessonFile(file, (progress) => {
          setUploadProgress(Math.round(progress));
        });
        console.log("File uploaded successfully:", fileUrl);
        setUploadProgress(0);

        // Determine content type based on file extension
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (['mp3', 'wav', 'ogg'].includes(fileExtension)) {
          contentType = 'audio';
        } else if (['mp4', 'webm', 'ogg'].includes(fileExtension)) {
          contentType = 'video';
        } else if (fileExtension === 'pdf') {
          contentType = 'pdf';
        } else {
          throw new Error('Unsupported file type');
        }
      }

      const lessonData = {
        ...lesson,
        module_id: moduleId,
        content_type: contentType,
        file_url: fileUrl
      };
      console.log("Saving lesson data:", lessonData);
      
      const savedLesson = lessonId
        ? await updateLesson(lessonId, lessonData)
        : await createLesson(lessonData);
      
      console.log("Lesson saved successfully:", savedLesson);
      toast.success('Lesson saved successfully');
      navigate(`/admin/course/${courseId}`);
    } catch (err) {
      console.error("Error saving lesson:", err);
      setError(err.message || 'An error occurred while saving the lesson');
      toast.error(err.message || 'An error occurred while saving the lesson');
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const removeFile = () => {
    setFile(null);
    setLesson(prev => ({ ...prev, content_type: '', file_url: '' }));
  };

  return (
    <div className="container mx-auto px-4 py-8 flex gap-4">
      <Card className="w-1/3">
        <CardHeader>
          <CardTitle>Module Information</CardTitle>
        </CardHeader>
        <CardContent>
          {module && (
            <>
              <h2 className="text-xl font-bold">{module.title}</h2>
              <p>{module.description}</p>
            </>
          )}
        </CardContent>
      </Card>
      <Card className="w-2/3">
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>{lessonId ? 'Edit Lesson' : 'Add New Lesson'}</CardTitle>
          <Button onClick={() => navigate(`/admin/course/${courseId}`)} variant="outline" size="sm">
            <ArrowLeft className="mr-2 h-4 w-4" /> Back to Course
          </Button>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <Input
              type="text"
              value={lesson.title}
              onChange={(e) => setLesson({ ...lesson, title: e.target.value })}
              placeholder="Lesson Title"
            />
            <Textarea
              value={lesson.content}
              onChange={(e) => setLesson({ ...lesson, content: e.target.value })}
              placeholder="Lesson Content"
            />
            <div>
              <input
                type="file"
                onChange={handleFileChange}
                className="hidden"
                id="file-upload"
                accept=".pdf,.mp3,.wav,.ogg,.mp4,.webm"
              />
              <label htmlFor="file-upload" className="cursor-pointer flex items-center justify-center p-2 border border-dashed border-gray-300 rounded-md">
                <Upload className="mr-2" /> Upload File (PDF, Audio, or Video)
              </label>
            </div>
            {file && (
              <div className="flex items-center justify-between p-2 bg-gray-100 rounded-md mb-2">
                <span>{file.name}</span>
                <Button onClick={removeFile} variant="ghost" size="sm">
                  <X className="h-4 w-4" />
                </Button>
              </div>
            )}
            {(uploadProgress > 0 || isUploading) && (
              <Progress value={uploadProgress} className="w-full" />
            )}
            <Button type="submit" disabled={isUploading}>
              {isUploading ? 'Saving...' : 'Save Lesson'}
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

export default LessonForm;