import { supabase } from './supabaseClient';

async function initializeUserCredits(userId) {
  const { data, error } = await supabase
    .from('user_credits')
    .select('*')
    .eq('user_id', userId)
    .single();

  if (error || !data) {
    // User doesn't exist in user_credits table, create a new entry
    const { error: insertError } = await supabase
      .from('user_credits')
      .insert({
        user_id: userId,
        credits: 100,
        last_reset_date: new Date().toISOString(),
        is_premium: false
      });

    if (insertError) {
      console.error('Error initializing user credits:', insertError);
    }
  }
}

export const signIn = async (email, password) => {
  const { data, error } = await supabase.auth.signInWithPassword({ email, password });
  if (error) throw error;
  await initializeUserCredits(data.user.id);
  return data.user;
};

export const signUp = async (email, password) => {
  const { data, error } = await supabase.auth.signUp({ email, password });
  if (error) throw error;
  await initializeUserCredits(data.user.id);
  return data.user;
};

export const resetPassword = async (email) => {
  const { data, error } = await supabase.auth.resetPasswordForEmail(email);
  if (error) throw error;
  return data;
};

export const signOut = async () => {
  const { error } = await supabase.auth.signOut();
  if (error) throw error;
};

export const getUser = async () => {
  const { data: { user } } = await supabase.auth.getUser();
  if (user) {
    await initializeUserCredits(user.id);
  }
  return user;
};