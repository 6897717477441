import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchCourseById, supabase } from '../supabaseClient';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { FaPlay, FaCheck } from 'react-icons/fa';

function CourseDetail() {
  const { courseId } = useParams();
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUser(user);
    };
    fetchUser();
  }, []);

  useEffect(() => {
    const loadCourse = async () => {
      if (!user) return;
      try {
        const courseData = await fetchCourseById(courseId, user.id);
        setCourse(courseData);
      } catch (error) {
        console.error('Error loading course:', error);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      loadCourse();
    }
  }, [courseId, user]);

  if (loading) {
    return <div>Loading course details...</div>;
  }

  if (!course) {
    return <div>Course not found</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="bg-white rounded-lg shadow-lg p-8">
        <div className="flex flex-col md:flex-row items-center md:items-start mb-8">
          <img src={course.cover_image_url} alt={course.title} className="w-64 h-64 object-cover aspect-square rounded-lg mb-4 md:mb-0 md:mr-8" />
          <div>
            <CardTitle className="text-3xl font-bold mb-2">{course.title}</CardTitle>
            <p className="text-xl text-gray-600 mb-4">{course.description}</p>
            <Button 
              onClick={() => navigate('/dashboard/courses')}
              variant="outline"
            >
              Back to Courses
            </Button>
          </div>
        </div>
        <div className="space-y-4">
          {course.modules.map((module) => (
            <Card key={module.id} className="bg-gray-100">
              <CardHeader>
                <CardTitle>{module.title}</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-gray-600 mb-4">{module.description}</p>
                <h3 className="text-xl font-bold mb-2">Lessons</h3>
                <div className="space-y-2">
                  {module.lessons.map((lesson) => (
                    <div key={lesson.id} className="flex items-center justify-between p-4 bg-white rounded-lg">
                      <div className="flex items-center flex-grow">
                        {lesson.progress && lesson.progress.completed && (
                          <FaCheck className="text-green-500 mr-2" />
                        )}
                        <h4 className="font-semibold">{lesson.title}</h4>
                      </div>
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => navigate(`/dashboard/courses/${courseId}/lessons/${lesson.id}`)}
                      >
                        {lesson.progress ? (lesson.progress.completed ? 'Review' : 'Continue') : 'Start'} Lesson
                      </Button>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </Card>
    </div>
  );
}

export default CourseDetail;