import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "../components/ui/card";
import { Button } from "../components/ui/button";

const greatMinds = [
  { name: 'Carl Jung', icon: 'Carl-Jung.png' },
  { name: 'Friedrich Nietzsche', icon: 'Friedrich-Nietzsche.png' },
  { name: 'Sigmund Freud', icon: 'Sigmund-Freud.png' },
  { name: 'Alan Watts', icon: 'Alan-Watts.png' },
  { name: 'Socrates', icon: 'Socrates.png' },
  { name: 'Plato', icon: 'Plato.png' },
  { name: 'Aristotle', icon: 'Aristotle.png' },
  { name: 'Confucius', icon: 'Confucius.png' },
  { name: 'Lao Tzu', icon: 'Lao-Tzu.png' },
  { name: 'Buddha', icon: 'Buddha.png' }
];

function ChatbotSelection() {
  return (
    <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
      {greatMinds.map((mind, index) => (
        <Card key={index} className="bg-white">
          <CardHeader className="flex flex-row items-center gap-4">
            <img 
              src={require(`../components/mind-icons/${mind.icon}`)} 
              alt={`${mind.name} icon`} 
              className="w-12 h-12 rounded-full"
            />
            <div>
              <CardTitle className="text-xl font-bold">{mind.name}</CardTitle>
              <CardDescription>Chat with the great philosopher</CardDescription>
            </div>
          </CardHeader>
          <CardContent>
            <Link to={`/chat/${mind.name.toLowerCase().replace(' ', '-')}`}>
              <Button className="w-full">Start Chat</Button>
            </Link>
          </CardContent>
        </Card>
      ))}
    </div>
  );
}

export default ChatbotSelection;