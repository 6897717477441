import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import { supabase } from '../supabaseClient';

// ... any existing utility functions ...

export const getCurrentUser = async () => {
  const { data: { user } } = await supabase.auth.getUser();
  return user;
};

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}
