import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Button } from "../components/ui/button";

function CategoryEntries({ category, entries, onBack }) {
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const nonEmptyEntries = entries.filter(entry => 
    entry[category.name] && entry[category.name].length > 0 && entry[category.name].some(item => item.trim() !== '')
  );

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        <Button onClick={onBack} variant="outline">← Back</Button>
        <h2 className="text-2xl font-bold">{category.title} Entries</h2>
      </div>
      {nonEmptyEntries.length > 0 ? (
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {nonEmptyEntries.map((entry) => (
            <Card key={entry.entry_date}>
              <CardHeader>
                <CardTitle>{formatDate(entry.entry_date)}</CardTitle>
              </CardHeader>
              <CardContent>
                <ul className="list-disc list-inside">
                  {entry[category.name]?.filter(item => item.trim() !== '').map((item, index) => (
                    <li key={index} className="text-gray-700">{item}</li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-500">No entries found for this category.</p>
      )}
    </div>
  );
}

export default CategoryEntries;