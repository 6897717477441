import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { supabase } from '../supabaseClient';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function SubscriptionButton() {
  const handleSubscribe = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error('User not authenticated');
        return;
      }

      const { data, error } = await supabase.functions.invoke('create-checkout-session', {
        body: { userId: user.id },
      });

      if (error) {
        console.error('Error creating checkout session:', error);
        return;
      }

      const stripe = await stripePromise;
      const { error: stripeError } = await stripe.redirectToCheckout({
        sessionId: data.sessionId
      });

      if (stripeError) {
        console.error('Stripe redirect error:', stripeError);
      }
    } catch (error) {
      console.error('Unexpected error:', error);
    }
  };

  return (
    <button onClick={handleSubscribe} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
      Subscribe Now
    </button>
  );
}

export default SubscriptionButton;