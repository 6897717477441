import React, { useState, useEffect, useRef } from 'react';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import Player from './Player';
import logoImage from '../assets/Dharmalab.png';
import { FiHeart, FiSearch, FiHome, FiMusic, FiBook, FiMessageSquare, FiVideo, FiPlus, FiX, FiZap } from 'react-icons/fi';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import { Button } from "../components/ui/button";
import './DashboardLayout.css';
import { usePlaylist } from '../PlaylistContext';
import CreatePlaylistModal from './CreatePlaylistModal';
import { FaPlay, FaPause } from 'react-icons/fa';
import LikeLoadingSpinner from './LikeLoadingSpinner';
import TrackOptionsMenu from './TrackOptionsMenu';
import PlaylistView from './PlaylistView';
import CreatePlaylistButton from './CreatePlaylistButton';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "./ui/alert-dialog";
import { checkCredits, deductCredits, CREDIT_COSTS } from '../utils/creditUtils';
import { loadStripe } from '@stripe/stripe-js';
import toast, { Toaster } from 'react-hot-toast';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function DashboardLayout() {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentTrack, setCurrentTrack] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayerVisible, setIsPlayerVisible] = useState(false);
  const [likedTracks, setLikedTracks] = useState({});
  const [loadingLikes, setLoadingLikes] = useState({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isCreatePlaylistModalOpen, setIsCreatePlaylistModalOpen] = useState(false);
  const { playlists, createPlaylist, fetchPlaylists, deletePlaylist } = usePlaylist();
  const [credits, setCredits] = useState(null);
  const [isPremium, setIsPremium] = useState(false);
  const [userName, setUserName] = useState('');
  const [user, setUser] = useState(null);

  const searchRef = useRef(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setUser(session?.user || null);
      setLoading(false);
    });

    const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setUser(session?.user || null);
      setLoading(false);
    });

    return () => {
      if (authListener && authListener.unsubscribe) {
        authListener.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (session?.user?.id) {
      fetchLikedTracks();
      fetchPlaylists();
      fetchUserCredits();
      fetchUserName();
    }
  }, [session]);

  async function fetchLikedTracks() {
    const { data, error } = await supabase
      .from('liked_tracks')
      .select('*')
      .eq('user_id', session.user.id);

    if (error) {
      console.error('Error fetching liked tracks:', error);
    } else {
      const likedTracksObj = data.reduce((acc, item) => {
        acc[item.track_id] = {
          id: item.id,
          title: item.title,
          artist: item.artist,
          album: item.album,
          artwork_url: item.artwork_url
        };
        return acc;
      }, {});
      setLikedTracks(likedTracksObj);
    }
  }

  async function handleLikeClick(track) {
    if (!session) {
      console.log('User not logged in');
      return;
    }

    setLoadingLikes(prev => ({ ...prev, [track.id]: true }));

    const isCurrentlyLiked = likedTracks[track.id];

    try {
      if (isCurrentlyLiked) {
        await supabase
          .from('liked_tracks')
          .delete()
          .eq('user_id', session.user.id)
          .eq('track_id', track.id);

        setLikedTracks(prev => {
          const newLikedTracks = { ...prev };
          delete newLikedTracks[track.id];
          return newLikedTracks;
        });
      } else {
        const { data, error } = await supabase
          .from('liked_tracks')
          .insert({
            user_id: session.user.id,
            track_id: track.id,
            title: track.title,
            artist: track.artist,
            album: track.album,
            artwork_url: track.artwork_url,
            audio_url: track.audio_url,
            duration: track.duration
          })
          .select();

        if (error) throw error;

        setLikedTracks(prev => ({
          ...prev,
          [track.id]: {
            user_id: session.user.id,
            track_id: track.id,
            title: track.title,
            artist: track.artist,
            album: track.album,
            artwork_url: track.artwork_url,
            audio_url: track.audio_url,
            duration: track.duration
          }
        }));
      }
    } catch (error) {
      console.error('Error updating liked track:', error);
    } finally {
      setLoadingLikes(prev => ({ ...prev, [track.id]: false }));
    }
  }

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate('/login');
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSearchInputChange = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.length > 2) {
      const { data, error } = await supabase
        .from('meditations')
        .select('id, title, artist, album, artwork_url')
        .or(`title.ilike.%${value}%,artist.ilike.%${value}%,album.ilike.%${value}%`)
        .limit(5);

      if (error) {
        console.error('Error searching meditations:', error);
      } else {
        setSearchResults(data);
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/dashboard/search?q=${encodeURIComponent(searchTerm)}`);
      setSearchResults([]);
    }
  };

  const handleSuggestionClick = (album) => {
    navigate(`/dashboard/streaming`, { state: { selectedAlbum: album } });
    setSearchResults([]);
    setSearchTerm('');
  };

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCreatePlaylist = async (title, description, emoji) => {
    try {
      await createPlaylist(title, description, emoji);
      setIsCreatePlaylistModalOpen(false);
      fetchPlaylists();
    } catch (error) {
      console.error('Error creating playlist:', error);
    }
  };

  const handleDeletePlaylist = async (playlistId) => {
    try {
      await deletePlaylist(playlistId);
      // No need to call fetchPlaylists here as deletePlaylist already updates the state
    } catch (error) {
      console.error('Error deleting playlist:', error);
      // Optionally, show an error message to the user
    }
  };

  const fetchUserCredits = async () => {
    if (session?.user) {
      const { data, error } = await supabase
        .from('user_credits')
        .select('credits, is_premium')
        .eq('user_id', session.user.id)
        .single();

      if (error) {
        console.error('Error fetching user credits:', error);
      } else {
        setCredits(data.credits);
        setIsPremium(data.is_premium);
        console.log('Fetched user credits:', data.credits);
      }
    }
  };

  async function fetchUserName() {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('full_name')
        .eq('id', session.user.id)
        .single();

      if (error) throw error;

      setUserName(data.full_name || session.user.email);
    } catch (error) {
      console.error('Error fetching user name:', error);
      setUserName(session.user.email);
    }
  }

  

  useEffect(() => {
    fetchUserCredits();
  }, [session]);

  const fetchUserData = async () => {
    if (!session?.user?.id) return;

    try {
      const [creditsResult, subscriptionResult] = await Promise.all([
        supabase
          .from('user_credits')
          .select('credits')
          .eq('user_id', session.user.id)
          .single(),
        supabase
          .from('subscriptions')
          .select('status, current_period_end')
          .eq('user_id', session.user.id)
          .maybeSingle()
      ]);

      if (creditsResult.error) throw creditsResult.error;
      setCredits(creditsResult.data.credits);

      if (subscriptionResult.data) {
        const isActive = subscriptionResult.data.status === 'active' && 
                         new Date(subscriptionResult.data.current_period_end) > new Date();
        setIsPremium(isActive);
      } else {
        setIsPremium(false);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      toast.error('Failed to fetch user data. Please try again.');
    }
  };

  const handleStreamTrack = async (track) => {
    if (!session) {
      toast.error('Please log in to stream tracks.');
      return;
    }

    const canStream = await checkCredits(session.user.id, 'STREAM_TRACK');
    if (!canStream && !isPremium) {
      toast.error('Insufficient credits to stream this track.');
      return;
    }

    if (!isPremium) {
      const newCredits = await deductCredits(session.user.id, CREDIT_COSTS.STREAM_TRACK);
      if (newCredits !== null) {
        setCredits(newCredits);
      } else {
        toast.error('Failed to deduct credits. Please try again.');
        return;
      }
    }

    setCurrentTrack(track);
    setIsPlaying(true);
  };

  const handleUpgradeClick = async () => {
    if (!user) {
      toast.error('Please log in to upgrade.');
      return;
    }

    const stripe = await stripePromise;
    
    console.log('STRIPE_PRICE_ID:', process.env.REACT_APP_STRIPE_PRICE_ID); // For debugging

    if (!process.env.REACT_APP_STRIPE_PRICE_ID) {
      console.error('Stripe Price ID is not set');
      toast.error('Configuration error. Please contact support.');
      return;
    }

    try {
      const { error } = await stripe.redirectToCheckout({
        lineItems: [{ price: process.env.REACT_APP_STRIPE_PRICE_ID, quantity: 1 }],
        mode: 'subscription',
        successUrl: `${window.location.origin}/dashboard?session_id={CHECKOUT_SESSION_ID}`,
        cancelUrl: `${window.location.origin}/dashboard`,
        clientReferenceId: user.id,
      });

      if (error) {
        console.error('Stripe Error:', error);
        toast.error('Failed to start checkout process.');
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      toast.error('An unexpected error occurred. Please try again.');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!session) {
    navigate('/login');
    return null;
  }

  return (
    <div className="dashboard-layout">
      <Toaster position="top-center" />
      <header className="dashboard-header">
        <div className="header-content">
          <div className="logo-container">
            <img src={logoImage} alt="Dharmalab Logo" className="app-logo" />
          </div>
          <div className="search-container" ref={searchRef}>
            <form onSubmit={handleSearch} className="search-form">
              <input
                type="text"
                placeholder="Search meditations..."
                value={searchTerm}
                onChange={handleSearchInputChange}
                className="search-input"
              />
              <button type="submit" className="search-button">
                <FiSearch />
              </button>
            </form>
            {searchResults.length > 0 && (
              <div className="search-suggestions">
                {searchResults.map((result) => (
                  <div 
                    key={result.id} 
                    className="search-suggestion-item cursor-pointer"
                    onClick={() => handleSuggestionClick(result.album)}
                  >
                    <img src={result.artwork_url} alt={result.title} className="search-suggestion-artwork" />
                    <div className="search-suggestion-info">
                      <div className="search-suggestion-name">{result.title}</div>
                      <div className="search-suggestion-artist">{result.artist}</div>
                      <div className="search-suggestion-album">{result.album}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="user-actions">
            <Link to="/dashboard/liked-tracks" className="nav-icon-link">
              <FiHeart className="nav-icon heart-icon" />
            </Link>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="user-icon-btn">
                  <svg viewBox="0 0 24 24" className="w-6 h-6">
                    <path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"/>
                  </svg>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem onSelect={() => navigate('/dashboard/profile')}>
                  Account
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={handleLogout}>
                  Sign Out
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </header>

      <div className="dashboard-main">
        <aside className={`sidebar ${isPlayerVisible ? 'player-visible' : ''}`}>
          <nav className="sidebar-nav">
            <Link to="/dashboard" className={`sidebar-link ${isActive('/dashboard') ? 'active' : ''}`}>
              <FiHome /> <span>Home</span>
            </Link>
            <Link to="/dashboard/ai-chatbots" className={`sidebar-link ${isActive('/dashboard/ai-chatbots') ? 'active' : ''}`}>
              <FiMessageSquare /> <span>Chat</span>
            </Link>
            <Link to="/dashboard/courses" className={`sidebar-link ${isActive('/dashboard/courses') ? 'active' : ''}`}>
             <FiVideo /> <span>Courses</span>
            </Link>
            <Link to="/dashboard/streaming" className={`sidebar-link ${isActive('/dashboard/streaming') ? 'active' : ''}`}>
              <FiMusic /> <span>Streaming</span>
            </Link>
            <Link to="/dashboard/liked-tracks" className="sidebar-link submenu-link">
              <span className="emoji">❤️</span> <span>Liked Tracks</span>
            </Link>
            {Array.isArray(playlists) && playlists.map((playlist) => (
              playlist && playlist.id && (
                <div key={playlist.id} className="flex items-center justify-between">
                  <Link to={`/dashboard/playlist/${playlist.id}`} className="sidebar-link submenu-link flex-grow">
                    <span className="emoji">{playlist.emoji || '🎵'}</span> <span>{playlist.title}</span>
                  </Link>
                  <AlertDialog>
                    <AlertDialogTrigger asChild>
                      <button className="text-gray-500 hover:text-red-500 p-1">
                        <FiX size={16} />
                      </button>
                    </AlertDialogTrigger>
                    <AlertDialogContent className="bg-white">
                      <AlertDialogHeader>
                        <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                        <AlertDialogDescription>
                          This will permanently delete the playlist '{playlist.emoji} {playlist.title}'.
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction onClick={() => handleDeletePlaylist(playlist.id)}>
                          Delete
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                </div>
              )
            ))}
            <button onClick={() => setIsCreatePlaylistModalOpen(true)} className="sidebar-link submenu-link create-playlist-button">
              <span className="emoji">+</span> <span>Create New Playlist</span>
            </button>
            <Link to="/dashboard/gratitude-journal" className={`sidebar-link ${isActive('/dashboard/gratitude-journal') ? 'active' : ''}`}>
              <FiBook /> <span>Journal</span>
            </Link>
            
          </nav>
          
          {/* Credit display card */}
          <div className={`credit-card mt-auto p-4 bg-white shadow rounded-lg ${isPlayerVisible ? 'player-visible' : ''}`}>
            <div className="flex items-center mb-2">
              <div className="w-10 h-10 bg-gray-300 rounded-full flex items-center justify-center mr-3">
                <svg viewBox="0 0 24 24" className="w-6 h-6 text-gray-600">
                  <path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"/>
                </svg>
              </div>
              <div>
                <p className="font-semibold">{userName}</p>
                <p className="text-sm text-gray-600">{isPremium ? 'Premium User' : 'Free Account'}</p>
                <div className="flex items-center">
                  <FiZap className="text-[#29cce5] mr-2" />
                  <span>{credits !== null ? `${credits}` : 'Loading...'}</span>
                </div>
              </div>
            </div>
            {!isPremium && (
              <Button
                onClick={handleUpgradeClick}
                className="w-full mt-2 bg-[#29cce5] hover:bg-[#20a3b8] text-white"
              >
                Upgrade
              </Button>
            )}
          </div>
        </aside>

        <main className={`content-wrapper ${isPlayerVisible ? 'pb-24' : ''}`}>
          <Outlet context={{
            currentTrack,
            setCurrentTrack,
            isPlaying,
            setIsPlaying,
            likedTracks,
            handleLikeClick,
            loadingLikes,
            setLoadingLikes,
            playlists,
            handleStreamTrack,
            credits,
            isPremium,
            setCredits
          }} />
        </main>
      </div>

      <Player 
        currentTrack={currentTrack}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        setIsPlayerVisible={setIsPlayerVisible}
        likedTracks={likedTracks}
        handleLikeClick={handleLikeClick}
        loadingLikes={loadingLikes}
        setLoadingLikes={setLoadingLikes}
        user={session?.user}
      />

      <CreatePlaylistModal
        isOpen={isCreatePlaylistModalOpen}
        onClose={() => setIsCreatePlaylistModalOpen(false)}
        onCreatePlaylist={handleCreatePlaylist}
      />

      <div className="mobile-menu">
        <Link to="/dashboard" className={`mobile-menu-link ${isActive('/dashboard') ? 'active' : ''}`}>
          <FiHome /> <span>Home</span>
        </Link>
        <Link to="/dashboard/ai-chatbots" className={`mobile-menu-link ${isActive('/dashboard/ai-chatbots') ? 'active' : ''}`}>
          <FiMessageSquare /> <span>Chat</span>
        </Link>
        <Link to="/dashboard/courses" className={`mobile-menu-link ${isActive('/dashboard/courses') ? 'active' : ''}`}>
          <FiVideo /> <span>Courses</span>
        </Link>
        <Link to="/dashboard/gratitude-journal" className={`mobile-menu-link ${isActive('/dashboard/gratitude-journal') ? 'active' : ''}`}>
          <FiBook /> <span>Journal</span>
        </Link>
        <Link to="/dashboard/streaming" className={`mobile-menu-link ${isActive('/dashboard/streaming') ? 'active' : ''}`}>
          <FiMusic /> <span>Streaming</span>
        </Link>
        
       
      </div>
    </div>
  );
}

export default DashboardLayout;
