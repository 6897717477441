import { supabase } from '../supabaseClient';

export const levels = [
    { name: "Seeker", range: [0, 100] },
    { name: "Novice", range: [101, 200] },
    { name: "Apprentice", range: [201, 300] },
    { name: "Student", range: [301, 400] },
    { name: "Adept", range: [401, 500] },
    { name: "Disciple", range: [501, 600] },
    { name: "Keeper", range: [601, 700] },
    { name: "Explorer", range: [701, 800] },
    { name: "Guardian", range: [801, 900] },
    { name: "Sage", range: [901, 1000] },
    { name: "Harbinger", range: [1001, 1100] },
    { name: "Master", range: [1101, 1200] },
    { name: "Pillar", range: [1201, 1300] },
    { name: "Mentor", range: [1301, 1400] },
    { name: "Conductor", range: [1401, 1500] },
    { name: "Bearer", range: [1501, 1600] },
    { name: "Enlightened", range: [1601, 1700] },
    { name: "Embodiment", range: [1701, 1800] },
    { name: "Vessel", range: [1801, 1900] },
    { name: "Beacon", range: [1901, 2000] },
    { name: "Bringer", range: [2001, 2100] },
    { name: "Guide", range: [2101, 2200] },
    { name: "Soul", range: [2201, 2300] },
    { name: "Ascendant", range: [2301, 2400] },
    { name: "Zen Master", range: [2401, 2500] }
];

export const MAX_LEVEL_POINTS = 2500;

export const calculateUserLevel = async (userId) => {
  try {
    // Fetch gratitude points
    const { data: gratitudeData, error: gratitudeError } = await supabase
      .from('gratitude_entries')
      .select('gratitude, intentions, happenings')
      .eq('user_id', userId);

    if (gratitudeError) throw gratitudeError;

    const gratitudePoints = gratitudeData.reduce((total, entry) => {
      return total + (entry.gratitude?.length || 0) + (entry.intentions?.length || 0) + (entry.happenings?.length || 0);
    }, 0);

    // Fetch course progress points
    const { data: progressData, error: progressError } = await supabase
      .from('user_progress')
      .select('completed')
      .eq('user_id', userId)
      .eq('completed', true);

    if (progressError) throw progressError;

    const coursePoints = progressData.length * 5;

    // Calculate total points
    const totalPoints = gratitudePoints + coursePoints;

    // Determine user level
    const userLevel = levels.find(level => totalPoints >= level.range[0] && totalPoints <= level.range[1]);

    // Calculate progress percentage
    const progressPercentage = (totalPoints / MAX_LEVEL_POINTS) * 100;

    return {
      level: userLevel ? userLevel.name : 'Unknown',
      totalPoints,
      progressPercentage: Math.min(progressPercentage, 100)
    };
  } catch (error) {
    console.error('Error calculating user level:', error);
    throw error;
  }
};