import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Home from './components/Home';
import DashboardLayout from './components/DashboardLayout';
import DashboardHome from './components/DashboardHome';
import ChatbotSelection from './components/ChatbotSelection';
import ChatInterface from './components/ChatInterface';
import Streaming from './components/Streaming';
import Login from './Login';
import GratitudeJournal from './components/GratitudeJournal';
import AccountManagement from './components/AccountManagement';
import LikedTracks from './components/LikedTracks';
import SubscriptionPage from './components/SubscriptionPage';
import SearchResults from './components/SearchResults';
import Courses from './components/Courses';
import CourseDetail from './components/CourseDetail';
import LessonDetail from './components/LessonDetail';
import './App.css';
import { PlaylistProvider } from './PlaylistContext';
import PlaylistView from './components/PlaylistView';
import { Toaster } from 'react-hot-toast';
import AdminDashboard from './components/AdminDashboard';
import CourseForm from './components/CourseForm';
import { supabase } from './supabaseClient';
import ModuleForm from './components/ModuleForm';
import CourseEditView from './components/CourseEditView';
import LessonForm from './components/LessonForm';
import { toast } from 'react-hot-toast';

function App() {
  const location = useLocation();

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');

    if (sessionId) {
      verifySubscription(sessionId);
    }
  }, [location]);

  const verifySubscription = async (sessionId) => {
    try {
      const { data, error } = await supabase.functions.invoke('verify-subscription', {
        body: { session_id: sessionId }
      });

      if (error) throw error;

      if (data.subscribed) {
        // Update user state or show success message
        console.log('Subscription successful!');
      }
    } catch (error) {
      console.error('Error verifying subscription:', error);
    }
  };

  return (
    <PlaylistProvider>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/callback" element={<Login />} />
          <Route path="/subscription" element={<SubscriptionPage />} />
          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route index element={<DashboardHome />} />
            <Route path="ai-chatbots" element={<ChatbotSelection />} />
            <Route path="search" element={<SearchResults />} />
            <Route path="streaming" element={<Streaming />} />
            <Route path="streaming/:albumName" element={<Streaming />} />
            <Route path="courses" element={<Courses />} />
            <Route path="courses/:courseId" element={<CourseDetail />} />
            <Route path="courses/:courseId/lessons/:lessonId" element={<LessonDetail />} />
            <Route path="gratitude-journal" element={<GratitudeJournal />} />
            <Route path="profile" element={<AccountManagement />} />
            <Route path="liked-tracks" element={<LikedTracks />} />
            <Route path="playlist/:playlistId" element={<PlaylistView />} />
          </Route>
          <Route path="/chat/:mindId" element={<DashboardLayout />}>
            <Route index element={<ChatInterface />} />
          </Route>
          {/* Admin routes */}
          <Route path="/admin" element={<AdminRoute><AdminDashboard /></AdminRoute>} />
          <Route path="/admin/course/new" element={<AdminRoute><CourseForm /></AdminRoute>} />
          <Route path="/admin/course/:courseId" element={<AdminRoute><CourseEditView /></AdminRoute>} />
          <Route path="/admin/course/:courseId/module/new" element={<AdminRoute><ModuleForm /></AdminRoute>} />
          <Route path="/admin/course/:courseId/module/:moduleId" element={<AdminRoute><ModuleForm /></AdminRoute>} />
          <Route path="/admin/course/:courseId/module/:moduleId/lesson/new" element={<AdminRoute><LessonForm /></AdminRoute>} />
          <Route path="/admin/course/:courseId/module/:moduleId/lesson/:lessonId" element={<AdminRoute><LessonForm /></AdminRoute>} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
        <Toaster position="bottom-right" />
      </div>
    </PlaylistProvider>
  );
}

// AdminRoute component to check if the user is an admin
function AdminRoute({ children }) {
  const [isAdmin, setIsAdmin] = React.useState(null);

  React.useEffect(() => {
    const checkAdminStatus = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setIsAdmin(user && user.email === 'niekpj@gmail.com');
    };

    checkAdminStatus();
  }, []);

  if (isAdmin === null) {
    return <div>Loading...</div>;
  }

  return isAdmin ? children : <Navigate to="/" replace />;
}

export default App;
