import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import LoadingSpinner from './LoadingSpinner';
import CategoryEntries from './CategoryEntries';
import { Button } from "../components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Input } from "../components/ui/input";

const sections = [
  { name: 'gratitude', emoji: '🙏', title: 'Gratitude' },
  { name: 'happenings', emoji: '📅', title: 'Happenings' },
  { name: 'intentions', emoji: '🎯', title: 'Intentions' },
  { name: 'todo', emoji: '✅', title: 'To-Do' }
];

function GratitudeJournal() {
  const [user, setUser] = useState(null);
  const [totalGratitudes, setTotalGratitudes] = useState(0);
  const [entries, setEntries] = useState([]);
  const [currentEntry, setCurrentEntry] = useState(
    Object.fromEntries(sections.map(section => [section.name, ['']]))
  );
  const [selectedDate, setSelectedDate] = useState(() => {
    const nycDate = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
    return new Date(nycDate).toISOString().split('T')[0];
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [summaryCounts, setSummaryCounts] = useState({
    gratitude: 0,
    happenings: 0,
    intentions: 0,
    todo: 0
  });
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [existingEntry, setExistingEntry] = useState(null);

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (user) {
      fetchGratitudes();
      fetchExistingEntry();
    }
  }, [user, selectedDate]);

  useEffect(() => {
    if (entries.length > 0) {
      const counts = entries.reduce((acc, entry) => {
        sections.forEach(section => {
          acc[section.name] += entry[section.name]?.length || 0;
        });
        return acc;
      }, { gratitude: 0, happenings: 0, intentions: 0, todo: 0 });

      setSummaryCounts(counts);
      setTotalGratitudes(counts.gratitude);
    }
  }, [entries]);

  async function fetchUser() {
    setIsLoading(true);
    const { data: { user } } = await supabase.auth.getUser();
    setUser(user);
    setIsLoading(false);
  }

  async function fetchGratitudes() {
    const { data, error } = await supabase
      .from('gratitude_entries')
      .select('*')
      .eq('user_id', user.id)
      .order('entry_date', { ascending: false });

    if (error) {
      console.error('Error fetching gratitudes:', error);
    } else {
      setEntries(data);
    }
  }

  async function fetchExistingEntry() {
    try {
      const { data, error } = await supabase
        .from('gratitude_entries')
        .select('*')
        .eq('user_id', user.id)
        .eq('entry_date', selectedDate)
        .single();

      if (error) {
        if (error.code === 'PGRST116') {
          setCurrentEntry(Object.fromEntries(sections.map(section => [section.name, ['']])));
          setExistingEntry(null);
        } else {
          throw error;
        }
      } else if (data) {
        setCurrentEntry(data);
        setExistingEntry(data);
      } else {
        setCurrentEntry(Object.fromEntries(sections.map(section => [section.name, ['']])));
        setExistingEntry(null);
      }
    } catch (error) {
      console.error('Error in fetchExistingEntry:', error);
      setError('Failed to fetch existing entry');
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const nonEmptyEntry = Object.fromEntries(
      Object.entries(currentEntry).map(([key, value]) => [key, Array.isArray(value) ? value.filter(item => item.trim() !== '') : value])
    );

    try {
      let result;
      if (existingEntry) {
        result = await supabase
          .from('gratitude_entries')
          .update(nonEmptyEntry)
          .eq('user_id', user.id)
          .eq('entry_date', selectedDate);
      } else {
        result = await supabase
          .from('gratitude_entries')
          .insert({
            user_id: user.id,
            entry_date: selectedDate,
            ...nonEmptyEntry
          });
      }

      const { data, error } = result;

      if (error) throw error;

      console.log(existingEntry ? 'Entry updated successfully:' : 'Entry saved successfully:', data);
      await fetchGratitudes();
      setError(existingEntry ? 'Entry updated successfully!' : 'Entry saved successfully!');
      setExistingEntry(nonEmptyEntry);
    } catch (error) {
      console.error('Error saving/updating entry:', error);
      setError(`Failed to ${existingEntry ? 'update' : 'save'} entry: ${error.message || 'Unknown error'}`);
    }
  };

  const handleInputChange = (section, index, value) => {
    setCurrentEntry(prev => {
      const updatedSection = [...prev[section]];
      updatedSection[index] = value;
      return { ...prev, [section]: updatedSection };
    });
  };

  const addField = (section) => {
    setCurrentEntry(prev => ({
      ...prev,
      [section]: [...prev[section], '']
    }));
  };

  const removeField = (section, index) => {
    setCurrentEntry(prev => ({
      ...prev,
      [section]: prev[section].filter((_, i) => i !== index)
    }));
  };

  async function deleteEntry(entryDate) {
    if (window.confirm('Are you sure you want to delete this entry?')) {
      const { error } = await supabase
        .from('gratitude_entries')
        .delete()
        .eq('user_id', user.id)
        .eq('entry_date', entryDate);

      if (error) {
        console.error('Error deleting entry:', error);
      } else {
        fetchGratitudes();
        if (entryDate === selectedDate) {
          setCurrentEntry(Object.fromEntries(sections.map(section => [section.name, ['']])));
          setExistingEntry(null);
        }
      }
    }
  }

  function formatDate(dateString) {
    const date = new Date(dateString + 'T00:00:00-05:00');
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'America/New_York' };
    return date.toLocaleDateString('en-US', options);
  }

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  if (isLoading) return <LoadingSpinner />;
  if (!user) return <div className="text-center py-8">Please log in to access this page.</div>;

  if (selectedCategory) {
    return (
      <CategoryEntries
        category={selectedCategory}
        entries={entries}
        onBack={() => setSelectedCategory(null)}
      />
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold mb-2">Journal</h1>
        <p className="text-gray-600">You have {totalGratitudes} things to be grateful for</p>
      </div>

      <div className="grid md:grid-cols-2 gap-8 mb-8">
        <div>
          <Card>
            <CardHeader>
              <CardTitle>{formatDate(selectedDate)}</CardTitle>
            </CardHeader>
            <CardContent>
              <form onSubmit={handleSubmit}>
                {sections.map(section => (
                  <div key={section.name} className="mb-4">
                    <div className="flex justify-between items-center mb-2">
                      <h3 className="text-lg font-semibold">
                        <span className="mr-2">{section.emoji}</span>
                        {section.title}
                      </h3>
                      <Button
                        type="button"
                        onClick={() => addField(section.name)}
                        size="sm"
                        variant="outline"
                      >
                        +
                      </Button>
                    </div>
                    {(currentEntry[section.name].length > 0 ? currentEntry[section.name] : ['']).map((item, index) => (
                      <div key={index} className="flex mb-2">
                        <Input
                          value={item}
                          onChange={(e) => handleInputChange(section.name, index, e.target.value)}
                          placeholder={`Enter ${section.title.toLowerCase()}...`}
                          className="flex-grow mr-2"
                        />
                        {currentEntry[section.name].length > 1 && (
                          <Button
                            type="button"
                            onClick={() => removeField(section.name, index)}
                            size="sm"
                            variant="outline"
                          >
                            ×
                          </Button>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
                <Button type="submit" className="w-full">
                  {existingEntry ? 'Update Entry' : 'Save Entry'}
                </Button>
              </form>
              {error && <p className="text-red-500 mt-2">{error}</p>}
            </CardContent>
          </Card>
        </div>

        <div>
          <Card>
            <CardHeader>
              <CardTitle>My Progress</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {sections.map(section => (
                  <div
                    key={section.name}
                    className="bg-gray-100 p-4 rounded cursor-pointer hover:bg-gray-200"
                    onClick={() => handleCategoryClick(section)}
                  >
                    <h3 className="font-semibold">{section.title}</h3>
                    <p>{summaryCounts[section.name]}</p>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>

      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Previous Entries</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {entries.map(entry => (
            <Card key={entry.entry_date}>
              <CardHeader>
                <CardTitle className="flex justify-between items-center">
                  <span 
                    className="cursor-pointer"
                    onClick={() => setSelectedDate(entry.entry_date)}
                  >
                    {formatDate(entry.entry_date)}
                  </span>
                  <Button
                    onClick={() => deleteEntry(entry.entry_date)}
                    size="sm"
                    variant="outline"
                  >
                    ×
                  </Button>
                </CardTitle>
              </CardHeader>
              <CardContent>
                {sections.map(section => (
                  <div key={section.name} className="mb-2">
                    <h4 className="font-semibold">
                      <span className="mr-2">{section.emoji}</span>
                      {section.title}
                    </h4>
                    <ul className="list-disc list-inside">
                      {entry[section.name]?.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
}

export default GratitudeJournal;