import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import './SearchResults.css';

function SearchResults() {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchQuery = new URLSearchParams(location.search).get('q');
    if (searchQuery) {
      performSearch(searchQuery);
    }
  }, [location.search]);

  const performSearch = async (query) => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('meditations')
        .select('id, title, artist, album, artwork_url')
        .or(`title.ilike.%${query}%,artist.ilike.%${query}%,album.ilike.%${query}%`)
        .limit(50);

      if (error) throw error;
      setResults(data);
    } catch (error) {
      console.error('Error searching meditations:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAlbumClick = (album) => {
    navigate(`/dashboard/streaming`, { state: { selectedAlbum: album } });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="search-results">
      <h2>Search Results</h2>
      {results.length === 0 ? (
        <p>No results found.</p>
      ) : (
        <div className="results-grid">
          {results.map((result) => (
            <div 
              key={result.id} 
              className="result-item cursor-pointer"
              onClick={() => handleAlbumClick(result.album)}
            >
              <img src={result.artwork_url} alt={result.title} className="result-artwork" />
              <div className="result-info">
                <div className="result-name">{result.title}</div>
                <div className="result-artist">{result.artist}</div>
                <div className="result-album">{result.album}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SearchResults;