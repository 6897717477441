import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from "./ui/button";
import { Card, CardContent } from "./ui/card";
import { Badge } from "./ui/badge";
import { MoveRight, PhoneCall } from "lucide-react";
import { Carousel, CarouselContent, CarouselItem } from "./ui/carousel";
import { supabase } from '../supabaseClient';

function Home() {
  const [artworkUrls, setArtworkUrls] = useState([]);
  const [api, setApi] = useState(null);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    async function fetchArtworkUrls() {
      const { data, error } = await supabase
        .from('meditations')
        .select('album, artwork_url')
        .not('artwork_url', 'is', null);
      
      if (error) {
        console.error('Error fetching artwork URLs:', error);
      } else {
        // Create a map to store unique album-artwork pairs
        const albumArtworkMap = new Map();
        
        // Filter out duplicates by album
        data.forEach(item => {
          if (!albumArtworkMap.has(item.album)) {
            albumArtworkMap.set(item.album, item.artwork_url);
          }
        });
        
        // Convert the map values (artwork URLs) to an array
        const uniqueUrls = Array.from(albumArtworkMap.values());
        
        // Shuffle the array to randomize the order
        const shuffledUrls = uniqueUrls.sort(() => Math.random() - 0.5);
        
        setArtworkUrls(shuffledUrls);
      }
    }

    fetchArtworkUrls();
  }, []);

  useEffect(() => {
    if (!api) {
      return;
    }

    const timer = setInterval(() => {
      if (api.selectedScrollSnap() + 1 === api.scrollSnapList().length) {
        setCurrent(0);
        api.scrollTo(0);
      } else {
        api.scrollNext();
        setCurrent(prev => prev + 1);
      }
    }, 3000);

    return () => clearInterval(timer);
  }, [api, current]);

  return (
    <div className="min-h-screen bg-white">
      {/* Header */}
      <header className="container mx-auto px-4 py-6 flex justify-between items-center">
        <h1 className="text-3xl font-bold text-gray-900">DharmaLab</h1>
        <nav className="space-x-4">
          <Button variant="outline" asChild>
            <Link to="/login">Login</Link>
          </Button>
          <Button asChild>
            <Link to="/register">Register</Link>
          </Button>
        </nav>
      </header>

      {/* Hero Section */}
      <div className="w-full py-20 lg:py-40">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 gap-8 items-center md:grid-cols-2">
            <div className="flex gap-4 flex-col">
              <div>
                <Badge variant="success">We're live!</Badge>
              </div>
              <div className="flex gap-4 flex-col">
                <h1 className="text-5xl md:text-7xl max-w-lg tracking-tighter text-left font-regular">
                  Discover Your Path to Inner Peace and Wisdom
                </h1>
                <p className="text-xl leading-relaxed tracking-tight text-muted-foreground max-w-md text-left">
                  Embark on a journey of self-discovery with AI-powered guidance and mindfulness practices. Our goal is to streamline your path to enlightenment, making it easier and faster than ever.
                </p>
              </div>
              <div className="flex flex-row gap-4">
                <Button size="lg" className="gap-4" variant="outline" asChild>
                  <Link to="/contact">
                    Contact Us <PhoneCall className="w-4 h-4" />
                  </Link>
                </Button>
                <Button size="lg" className="gap-4" asChild>
                  <Link to="/register">
                    Start Your Journey <MoveRight className="w-4 h-4" />
                  </Link>
                </Button>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8">
            <div className="bg-muted rounded-md aspect-square p-4">
                  <img src="https://iozjdbfcpjjhucqmahka.supabase.co/storage/v1/object/public/site_assets/dharmalab%20albums.webp?t=2024-10-20T16%3A21%3A18.080Z" alt="meditations dharmalab"  />
                  <h2 className="text-2xl font-bold mb-4">Unlimited Meditations</h2>
                  
                  
                    <p className="text-sm mb-2">Created by our certified guides.</p>
                    <p className="text-sm mb-4">Get results quickly, using the latest innovations in Binaural technology.</p>
                    
                    
                  
               
              </div>
              <div className="bg-muted rounded-md row-span-2 relative z-10 p-6 flex flex-col h-full justify-between">
              <img src="https://iozjdbfcpjjhucqmahka.supabase.co/storage/v1/object/public/site_assets/jung%20chat%20dharmalab.webp?t=2024-10-20T16%3A07%3A17.949Z" alt="ai chat greats minds dharmalab"  />
              
                    <h2 className="text-2xl font-bold mb-4">Get Meditation Advice From Buddha!</h2>
                    <div>
                      <p className="text-sm mb-2">Engage in enlightening conversations with AI-powered representations of history's greatest minds.</p>
                      <p className="text-sm mb-4">From Buddha to Socrates, explore wisdom across ages and cultures.</p>
                      <Button variant="outline" size="sm">Start Chatting</Button>
                    
                  </div>
              </div>
              
              <div className="bg-muted rounded-md aspect-square p-4">
                
                  
                  <div className="relative z-10 p-1 flex flex-col h-full justify-between">
                  
                    <h2 className="text-2xl font-bold mb-4">Over 100+ Courses!</h2>
                    
                    
                      <p className="text-sm mb-2">Get ready to completely change your mindset.</p>
                      <p className="text-sm mb-4">We offer courses ranging from philosophy to spirituality. Life is how you look at it!</p>
                      <img src="https://iozjdbfcpjjhucqmahka.supabase.co/storage/v1/object/public/site_assets/Screenshot%202024-10-20%20at%2013.12.27.webp?t=2024-10-20T16%3A14%3A08.128Z" alt="ai chat greats minds dharmalab"  />
                      
                    
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* New Trusted By Section with Carousel */}
      <div className="w-full py-20 lg:py-40">
        <div className="container mx-auto">
          <div className="grid grid-cols-5 gap-10 items-center">
            <h3 className="text-xl tracking-tighter lg:max-w-xl font-regular text-left">
              Trusted by meditation enthusiasts
            </h3>
            <div className="relative w-full col-span-4">
              <div className="bg-gradient-to-r from-background via-white/0 to-background z-10 absolute left-0 top-0 right-0 bottom-0 w-full h-full"></div>
              <Carousel setApi={setApi} className="w-full">
                <CarouselContent>
                  {artworkUrls.map((url, index) => (
                    <CarouselItem
                      className="basis-1/4 lg:basis-1/6"
                      key={index}
                    >
                      <div className="flex rounded-md aspect-square bg-muted items-center justify-center p-2">
                        <img src={url} alt={`Album Artwork ${index + 1}`} className="w-full h-full object-cover rounded-md" />
                      </div>
                    </CarouselItem>
                  ))}
                </CarouselContent>
              </Carousel>
            </div>
          </div>
        </div>
      </div>

      

      {/* Testimonials Section */}
      <section className="bg-gray-50 py-20">
        <div className="container mx-auto px-4">
          <h3 className="text-3xl font-bold text-gray-900 text-center mb-12">What Our Users Say</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[1, 2, 3].map((i) => (
              <Card key={i}>
                <CardContent className="pt-6">
                  <p className="text-gray-600 mb-4">"DharmaLab has transformed my approach to mindfulness and self-reflection. The AI chats are truly enlightening!"</p>
                  <div className="flex items-center">
                    <div className="w-12 h-12 bg-gray-200 rounded-full mr-4"></div>
                    <div>
                      <p className="font-semibold text-gray-900">Jane Doe</p>
                      <p className="text-gray-600 text-sm">Mindfulness Practitioner</p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-white py-12">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div>
              <h4 className="text-lg font-semibold mb-4">Company</h4>
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-gray-300">About Us</a></li>
                <li><a href="#" className="hover:text-gray-300">Careers</a></li>
                <li><a href="#" className="hover:text-gray-300">Contact</a></li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Resources</h4>
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-gray-300">Blog</a></li>
                <li><a href="#" className="hover:text-gray-300">Guides</a></li>
                <li><a href="#" className="hover:text-gray-300">FAQ</a></li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Legal</h4>
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-gray-300">Privacy Policy</a></li>
                <li><a href="#" className="hover:text-gray-300">Terms of Service</a></li>
                <li><a href="#" className="hover:text-gray-300">Cookie Policy</a></li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Connect</h4>
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-gray-300">Twitter</a></li>
                <li><a href="#" className="hover:text-gray-300">Facebook</a></li>
                <li><a href="#" className="hover:text-gray-300">Instagram</a></li>
              </ul>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-800 text-center">
            <p>&copy; 2024 DharmaLab. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Home;
