export const quotes = [
    {
      quote: "The only true wisdom is in knowing you know nothing.",
      author: "Socrates"
    },
    
    {
      quote: "Be the change that you wish to see in the world.",
      author: "Mahatma Gandhi"
    },
    {
      quote: "In three words I can sum up everything I've learned about life: it goes on.",
      author: "Robert Frost"
    },
    {
      quote: "The unexamined life is not worth living.",
      author: "Socrates"
    },
    {
      quote: "To be or not to be. that is the question.",
      author: "William Shakespeare"
    },
    {
      quote: "I think, therefore I am.",
      author: "René Descartes"
    },
    {
      quote: "He who has a why to live can bear almost any how.",
      author: "Friedrich Nietzsche"
    },
    {
      quote: "The greatest glory in living lies not in never falling, but in rising every time we fall.",
      author: "Nelson Mandela"
    },
    {
      quote: "The only way to do great work is to love what you do.",
      author: "Steve Jobs"
    }
  ];