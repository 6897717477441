import React from 'react';
import { Progress } from "./ui/progress";

function CourseProgress({ progress }) {
  const roundedProgress = Math.round(progress);

  return (
    <div className="w-full mt-2">
      <div className="flex items-center">
        <Progress value={roundedProgress} className="flex-grow h-2" />
        <span className="text-xs ml-2 text-gray-500">{roundedProgress}%</span>
      </div>
    </div>
  );
}

export default CourseProgress;