import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase, fetchCourseById, updateUserProgress } from '../supabaseClient';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { FaCheck, FaPlay } from 'react-icons/fa';

function LessonDetail() {
  const { courseId, lessonId } = useParams();
  const navigate = useNavigate();
  const [course, setCourse] = useState(null);
  const [lesson, setLesson] = useState(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUser(user);
    };
    fetchUser();
  }, []);

  useEffect(() => {
    const loadCourseAndLesson = async () => {
      if (!user) return;
      try {
        const courseData = await fetchCourseById(courseId, user.id);
        setCourse(courseData);
        const lessonData = courseData.modules
          .flatMap(module => module.lessons)
          .find(lesson => lesson.id === lessonId);
        setLesson(lessonData);
      } catch (error) {
        console.error('Error loading course or lesson:', error);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      loadCourseAndLesson();
    }
  }, [courseId, lessonId, user]);

  const handleLessonClick = (newLessonId) => {
    navigate(`/dashboard/courses/${courseId}/lessons/${newLessonId}`);
  };

  const findNextLesson = (course, currentLessonId) => {
    let foundCurrent = false;
    for (const module of course.modules) {
      for (const lesson of module.lessons) {
        if (foundCurrent) {
          return lesson;
        }
        if (lesson.id === currentLessonId) {
          foundCurrent = true;
        }
      }
    }
    return null;
  };

  const isLastLesson = (course, currentLessonId) => {
    const allLessons = course.modules.flatMap(module => module.lessons);
    return allLessons[allLessons.length - 1].id === currentLessonId;
  };

  const handleCompletionToggle = async () => {
    if (!user || !lesson) return;
    const newCompletedState = !(lesson.progress && lesson.progress.completed);
    try {
      await updateUserProgress(user.id, lessonId, newCompletedState);
      setLesson(prev => ({...prev, progress: {...prev.progress, completed: newCompletedState}}));
      
      // Update the course state to reflect the change
      setCourse(prevCourse => {
        const updatedModules = prevCourse.modules.map(module => ({
          ...module,
          lessons: module.lessons.map(l => 
            l.id === lessonId ? {...l, progress: {...l.progress, completed: newCompletedState}} : l
          )
        }));
        return {...prevCourse, modules: updatedModules};
      });

      if (newCompletedState) {
        if (isLastLesson(course, lessonId)) {
          navigate(`/dashboard/courses/${courseId}`);
        } else {
          const nextLesson = findNextLesson(course, lessonId);
          if (nextLesson) {
            navigate(`/dashboard/courses/${courseId}/lessons/${nextLesson.id}`);
          } else {
            navigate(`/dashboard/courses/${courseId}`);
          }
        }
      }
    } catch (error) {
      console.error('Error updating progress:', error);
    }
  };

  const renderContent = () => {
    if (!lesson) return null;

    switch (lesson.content_type) {
      case 'video':
        return (
          <div className="aspect-w-16 aspect-h-9 mb-4">
            <video controls className="w-full rounded-lg">
              <source src={lesson.file_url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        );
      case 'audio':
        return (
          <audio controls className="w-full mb-4">
            <source src={lesson.file_url} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        );
      case 'pdf':
        return (
          <iframe
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(lesson.file_url)}&embedded=true`}
            width="100%"
            height="600px"
            className="mb-4 rounded-lg"
            frameBorder="0"
          ></iframe>
        );
      default:
        return null;
    }
  };

  if (loading) {
    return <div>Loading lesson...</div>;
  }

  if (!lesson || !course) {
    return <div>Lesson or course not found</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8 flex gap-4">
      <Card className="w-1/4 bg-white rounded-lg shadow-lg p-4 overflow-y-auto max-h-screen">
        <CardHeader>
          <img src={course.cover_image_url} alt={course.title} className="w-full h-48 object-cover rounded-lg mb-4" />
          <CardTitle className="text-xl font-bold mb-2">{course.title}</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          {course.modules.map((module) => (
            <Card key={module.id} className="bg-gray-100">
              <CardHeader>
                <CardTitle className="text-lg">{module.title}</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-2">
                  {module.lessons.map((lessonItem) => (
                    <div 
                      key={lessonItem.id} 
                      className="flex items-center justify-between p-2 bg-white rounded-lg cursor-pointer hover:bg-gray-50"
                      onClick={() => handleLessonClick(lessonItem.id)}
                    >
                      <div className="flex items-center flex-grow">
                        {lessonItem.progress && lessonItem.progress.completed && (
                          <FaCheck className="text-green-500 mr-2" />
                        )}
                        <span className={`font-semibold ${lessonItem.id === lessonId ? "text-blue-600" : ""}`}>
                          {lessonItem.title}
                        </span>
                      </div>
                      <Button
                        variant="ghost"
                        size="sm"
                        className="ml-2"
                      >
                        <FaPlay className="mr-1" />
                        {lessonItem.progress ? (lessonItem.progress.completed ? 'Review' : 'Continue') : 'Start'}
                      </Button>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          ))}
        </CardContent>
        <Button 
          onClick={() => navigate(`/dashboard/courses/${courseId}`)}
          variant="outline"
          className="mt-4 w-full"
        >
          Back to Course
        </Button>
      </Card>
      
      <Card className="w-3/4 bg-white rounded-lg shadow-lg p-8">
        <CardHeader>
          <CardTitle className="text-3xl font-bold mb-2">{lesson.title}</CardTitle>
        </CardHeader>
        <CardContent>
          {renderContent()}
          <div className="prose max-w-none mb-4">
            <p>{lesson.content}</p>
          </div>
          <div className="flex justify-end">
            <Button onClick={handleCompletionToggle}>
              {lesson.progress && lesson.progress.completed ? "Completed" : "Mark as Completed"}
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default LessonDetail;
