import { supabase } from './supabaseClient';

export async function logUserActivity(userId, activityType, details) {
  const { data, error } = await supabase
    .from('user_activities')
    .insert([
      { user_id: userId, activity_type: activityType, details: details }
    ]);

  if (error) {
    console.error('Error logging user activity:', error);
  }
}