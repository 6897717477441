import React, { useState, useEffect } from 'react';
import { supabase, fetchCourses } from '../supabaseClient';
import { useNavigate, Link } from 'react-router-dom';
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/ui/table";
import { Checkbox } from "src/components/ui/checkbox";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "src/components/ui/dropdown-menu";
import { ChevronDown, Trash2, Edit, Plus, Eye } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from "src/components/ui/card";

function AdminDashboard() {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('title');
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [adminEmails, setAdminEmails] = useState(['niekpj@gmail.com', 'ourtopsecrethouse@gmail.com']); // List of admin emails
  const navigate = useNavigate();

  useEffect(() => {
    checkAdminStatus();
    loadCourses();
  }, []);

  const checkAdminStatus = async () => {
    const { data: { user }, error } = await supabase.auth.getUser();
    if (error) {
      console.error('Error fetching user:', error);
      navigate('/');
      return;
    }

    if (!user) {
      console.log('No user found');
      navigate('/');
      return;
    }

    console.log('Logged in user email:', user.email);
    console.log('Admin emails:', adminEmails);

    if (!adminEmails.includes(user.email)) {
      console.log('User is not an admin');
      navigate('/');
    } else {
      console.log('User is an admin');
    }
  };

  const loadCourses = async () => {
    setLoading(true);
    try {
      const coursesData = await fetchCourses();
      setCourses(coursesData);
    } catch (error) {
      console.error('Error loading courses:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (field) => {
    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectCourse = (courseId) => {
    setSelectedCourses(prev => 
      prev.includes(courseId) 
        ? prev.filter(id => id !== courseId)
        : [...prev, courseId]
    );
  };

  const handleSelectAll = () => {
    setSelectedCourses(
      selectedCourses.length === courses.length 
        ? [] 
        : courses.map(course => course.id)
    );
  };

  const handleBulkDelete = async () => {
    if (window.confirm(`Are you sure you want to delete ${selectedCourses.length} courses?`)) {
      try {
        await supabase
          .from('courses')
          .delete()
          .in('id', selectedCourses);
        loadCourses();
        setSelectedCourses([]);
      } catch (error) {
        console.error('Error deleting courses:', error);
      }
    }
  };

  const handleCourseClick = (courseId) => {
    navigate(`/admin/course/${courseId}`);
  };

  const handleAddAdminEmail = (email) => {
    setAdminEmails(prev => [...prev, email]);
  };

  const handleRemoveAdminEmail = (email) => {
    setAdminEmails(prev => prev.filter(e => e !== email));
  };

  const filteredAndSortedCourses = courses
    .filter(course => course.title.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort((a, b) => {
      if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Admin Dashboard</h1>
      <div className="flex space-x-4">
        <Card className="w-1/2">
          <CardHeader>
            <CardTitle>Courses</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="mb-4 flex justify-between items-center">
              <Input
                type="text"
                placeholder="Search courses..."
                value={searchTerm}
                onChange={handleSearch}
                className="max-w-xs"
              />
              <Button asChild>
                <Link to="/admin/course/new">
                  <Plus className="mr-2 h-4 w-4" /> Add New Course
                </Link>
              </Button>
            </div>
            {selectedCourses.length > 0 && (
              <div className="mb-4">
                <Button variant="destructive" onClick={handleBulkDelete}>
                  <Trash2 className="mr-2 h-4 w-4" /> Delete Selected ({selectedCourses.length})
                </Button>
              </div>
            )}
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[50px]">
                    <Checkbox
                      checked={selectedCourses.length === courses.length}
                      onCheckedChange={handleSelectAll}
                    />
                  </TableHead>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('title')}>
                    Title {sortField === 'title' && (sortOrder === 'asc' ? '↑' : '↓')}
                  </TableHead>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('created_at')}>
                    Created At {sortField === 'created_at' && (sortOrder === 'asc' ? '↑' : '↓')}
                  </TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredAndSortedCourses.map(course => (
                  <TableRow key={course.id}>
                    <TableCell>
                      <Checkbox
                        checked={selectedCourses.includes(course.id)}
                        onCheckedChange={() => handleSelectCourse(course.id)}
                      />
                    </TableCell>
                    <TableCell>
                      <span 
                        className="cursor-pointer hover:underline" 
                        onClick={() => handleCourseClick(course.id)}
                      >
                        {course.title}
                      </span>
                    </TableCell>
                    <TableCell>{new Date(course.created_at).toLocaleDateString()}</TableCell>
                    <TableCell>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="ghost" className="h-8 w-8 p-0">
                            <ChevronDown className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuItem onClick={() => handleCourseClick(course.id)}>
                            <Edit className="mr-2 h-4 w-4" /> Edit
                          </DropdownMenuItem>
                          <DropdownMenuItem onClick={() => navigate(`/admin/courses/${course.id}`)}>
                            <Eye className="mr-2 h-4 w-4" /> View
                          </DropdownMenuItem>
                          <DropdownMenuItem onClick={() => {/* implement delete */}}>
                            <Trash2 className="mr-2 h-4 w-4" /> Delete
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
        <Card className="w-1/2">
          <CardHeader>
            <CardTitle>Course Details</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Select a course to view or edit its details.</p>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default AdminDashboard;