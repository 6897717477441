import React, { useState, useEffect } from 'react';
import { usePlaylist } from '../PlaylistContext';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "./ui/dialog";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import EmojiPicker from 'emoji-picker-react';
import { toast } from 'react-hot-toast';
import { getUser } from '../auth';

function CreatePlaylistModal({ isOpen, onClose, editPlaylist = null, trackToAdd = null }) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [emoji, setEmoji] = useState('🎵');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { createPlaylist, updatePlaylist, addTrackToPlaylist } = usePlaylist();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await getUser();
      setUser(currentUser);
    };
    fetchUser();
  }, []);

  useEffect(() => {
    if (editPlaylist) {
      setTitle(editPlaylist.title);
      setDescription(editPlaylist.description);
      setEmoji(editPlaylist.emoji);
    }
  }, [editPlaylist]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      toast.error('You must be logged in to create or edit a playlist');
      return;
    }
    try {
      let playlist;
      if (editPlaylist) {
        playlist = await updatePlaylist(editPlaylist.id, { title, description, emoji });
        toast.success('Playlist updated successfully');
      } else {
        playlist = await createPlaylist(title, description, emoji);
        toast.success('Playlist created successfully');
      }

      if (trackToAdd && playlist) {
        await addTrackToPlaylist(playlist.id, trackToAdd.id);
        toast.success('Track added to the playlist');
      }

      onClose();
      setTitle('');
      setDescription('');
      setEmoji('🎵');
    } catch (error) {
      console.error('Error creating/updating playlist:', error);
      toast.error('Failed to create/update playlist');
    }
  };

  const onEmojiClick = (emojiObject) => {
    setEmoji(emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="bg-modal-background">
        <DialogHeader>
          <DialogTitle>{editPlaylist ? 'Edit Playlist' : 'Create New Playlist'}</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="title" className="text-right">
                Title
              </Label>
              <Input
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="description" className="text-right">
                Description
              </Label>
              <Input
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="emoji" className="text-right">
                Emoji
              </Label>
              <div className="col-span-3 relative">
                <Button
                  type="button"
                  onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  className="w-full justify-start bg-background text-foreground border border-input"
                  variant="outline"
                >
                  {emoji}
                </Button>
                {showEmojiPicker && (
                  <div className="absolute z-10 mt-2">
                    <EmojiPicker 
                      onEmojiClick={onEmojiClick} 
                      height={300} 
                      width="100%" 
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <DialogFooter>
            <Button type="submit">{editPlaylist ? 'Update Playlist' : 'Create Playlist'}</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default CreatePlaylistModal;