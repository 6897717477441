import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { useOutletContext, Link } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import { quotes } from './quotes';
import { FaPlay, FaPause } from 'react-icons/fa';
import './DashboardHome.css';
import CourseProgress from './CourseProgress';
import { calculateUserLevel } from './levelCalculation';
import { checkCredits, deductCredits, CREDIT_COSTS } from '../utils/creditUtils';
import { Toaster } from 'react-hot-toast';

function DashboardHome() {
  const { setCurrentTrack, setIsPlaying, isPremium, setCredits } = useOutletContext();
  const [user, setUser] = useState(null);
  const [activities, setActivities] = useState([]);
  const [recentTracks, setRecentTracks] = useState([]);
  const [recentCourses, setRecentCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [quote, setQuote] = useState(null);
  const [journalSummary, setJournalSummary] = useState({
    gratitude: 0,
    happenings: 0,
    intentions: 0,
    todo: 0
  });
  const [hoveredTrack, setHoveredTrack] = useState(null);
  const [zenLevel, setZenLevel] = useState("");
  const [overallProgress, setOverallProgress] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);
  const [levelIcon, setLevelIcon] = useState(null);

  useEffect(() => {
    fetchUser();
    setQuote(quotes[Math.floor(Math.random() * quotes.length)]);
  }, []);

  useEffect(() => {
    if (user) {
      fetchActivities();
      fetchJournalSummary();
      fetchRecentTracks();
      fetchRecentCourses();
      fetchUserLevel();
    }
  }, [user]);

  async function fetchUser() {
    try {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error) throw error;
      setUser(user);
    } catch (error) {
      console.error('Error fetching user:', error);
      setError('Failed to fetch user information');
    } finally {
      setLoading(false);
    }
  }

  async function fetchActivities() {
    try {
      const { data, error } = await supabase
        .from('user_activities')
        .select('*')
        .eq('user_id', user.id)
        .or('activity_type.eq.chat_started,activity_type.eq.chat_resumed,activity_type.eq.message_sent')
        .order('created_at', { ascending: false });

      if (error) throw error;

      const groupedActivities = data.reduce((acc, activity) => {
        const mindName = activity.details.split(' with ')[1];
        if (!acc[mindName] || new Date(activity.created_at) > new Date(acc[mindName].created_at)) {
          acc[mindName] = activity;
        }
        return acc;
      }, {});

      setActivities(Object.values(groupedActivities).sort((a, b) => 
        new Date(b.created_at) - new Date(a.created_at)
      ));
    } catch (error) {
      console.error('Error fetching activities:', error);
      setError('Failed to fetch recent chats');
    }
  }

  async function fetchJournalSummary() {
    try {
      const { data, error } = await supabase
        .from('gratitude_entries')
        .select('gratitude, happenings, intentions, todo')
        .eq('user_id', user.id);

      if (error) throw error;

      const summary = data.reduce((acc, entry) => {
        acc.gratitude += entry.gratitude ? entry.gratitude.length : 0;
        acc.happenings += entry.happenings ? entry.happenings.length : 0;
        acc.intentions += entry.intentions ? entry.intentions.length : 0;
        acc.todo += entry.todo ? entry.todo.length : 0;
        return acc;
      }, { gratitude: 0, happenings: 0, intentions: 0, todo: 0 });

      setJournalSummary(summary);
    } catch (error) {
      console.error('Error fetching journal summary:', error);
    }
  }

  async function fetchRecentTracks() {
    try {
      const { data, error } = await supabase
        .from('recently_played_tracks')
        .select('*')
        .eq('user_id', user.id)
        .order('played_at', { ascending: false });

      if (error) throw error;

      const uniqueTracks = data.reduce((acc, track) => {
        if (!acc[track.track_id] || new Date(track.played_at) > new Date(acc[track.track_id].played_at)) {
          acc[track.track_id] = track;
        }
        return acc;
      }, {});

      setRecentTracks(Object.values(uniqueTracks).slice(0, 5));
    } catch (error) {
      console.error('Error fetching recent tracks:', error);
    }
  }

  async function fetchRecentCourses() {
    try {
      // Fetch the last 50 completed lessons
      const { data: recentProgress, error: progressError } = await supabase
        .from('user_progress')
        .select('lesson_id, updated_at')
        .eq('user_id', user.id)
        .eq('completed', true)
        .order('updated_at', { ascending: false })
        .limit(50);

      if (progressError) throw progressError;

      if (!recentProgress || recentProgress.length === 0) {
        setRecentCourses([]);
        return;
      }

      // Get the lesson IDs
      const lessonIds = recentProgress.map(progress => progress.lesson_id);

      // Fetch the corresponding lessons with their modules and courses
      const { data: lessons, error: lessonsError } = await supabase
        .from('lessons')
        .select(`
          id,
          module_id,
          modules (
            id,
            course_id
          )
        `)
        .in('id', lessonIds);

      if (lessonsError) throw lessonsError;

      // Get unique course IDs
      const courseIds = [...new Set(lessons.map(lesson => lesson.modules.course_id))];

      // Fetch course details
      const { data: courses, error: coursesError } = await supabase
        .from('courses')
        .select('id, title, cover_image_url')
        .in('id', courseIds);

      if (coursesError) throw coursesError;

      // Map courses to include last completed date
      const recentCourses = courses.map(course => {
        const lastLesson = lessons.find(lesson => lesson.modules.course_id === course.id);
        const lastProgress = recentProgress.find(progress => progress.lesson_id === lastLesson.id);
        return {
          ...course,
          lastCompletedAt: lastProgress.updated_at
        };
      });

      // Sort by most recent and take top 5
      recentCourses.sort((a, b) => new Date(b.lastCompletedAt) - new Date(a.lastCompletedAt));
      setRecentCourses(recentCourses.slice(0, 5));

    } catch (error) {
      console.error('Error fetching recent courses:', error);
      setError('Failed to fetch recent courses');
    }
  }

  async function playPause(track) {
    try {
      if (!isPremium) {
        const canStream = await checkCredits(user.id, 'stream');
        if (!canStream) return;

        const newCredits = await deductCredits(user.id, CREDIT_COSTS.STREAM_TRACK);
        if (newCredits === null) return;

        setCredits(newCredits);
      }

      const { data, error } = await supabase
        .from('meditations')
        .select('id, audio_url, duration')
        .eq('id', track.track_id)
        .single();

      if (error) throw error;

      const trackToPlay = {
        id: data.id,
        title: track.title,
        artist: track.artist,
        album: track.album,
        artwork_url: track.artwork_url,
        audio_url: data.audio_url,
        duration: data.duration,
      };

      setCurrentTrack(trackToPlay);
      setIsPlaying(true);

      await supabase.from('recently_played_tracks').upsert({
        user_id: user.id,
        track_id: track.track_id,
        title: track.title,
        artist: track.artist,
        album: track.album,
        artwork_url: track.artwork_url,
        played_at: new Date().toISOString()
      }, {
        onConflict: 'user_id,track_id'
      });

      fetchRecentTracks();
    } catch (error) {
      console.error('Error playing track:', error);
    }
  }

  async function fetchUserLevel() {
    try {
      const { level, totalPoints, progressPercentage } = await calculateUserLevel(user.id);
      setZenLevel(level);
      setTotalPoints(totalPoints);
      setOverallProgress(progressPercentage);
    } catch (error) {
      console.error('Error fetching user level:', error);
    }
  }

  function formatDuration(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  const getLevelIcon = (points) => {
    const level = Math.min(Math.floor(points / 100) + 1, 25);
    const iconFileName = `${level.toString().padStart(2, '0')}.png`;
    try {
      return require(`../components/zen-icons/${iconFileName}`);
    } catch (error) {
      console.error('Error loading level icon:', error);
      return null;
    }
  };

  // Calculate progress to next level
  const calculateProgressToNextLevel = (totalPoints) => {
    return (totalPoints % 100);
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <div className="text-red-500">Error: {error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Dashboard</h1>
      
      <div className="mb-8 text-center">
        <h1 className="text-3xl font-bold mb-2">Welcome, <span className="text-indigo-600">{user?.user_metadata?.first_name || user?.email || 'User'}</span></h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
        <Link to="/dashboard/gratitude-journal" className="bg-white shadow-md rounded-lg p-6 flex flex-col items-center">
          <h2 className="text-xl font-semibold mb-4">Journal Summary</h2>
          <div className="grid grid-cols-2 gap-x-8 gap-y-4 text-center w-full">
            <div className="journal-summary-item">
              <p className="text-2xl font-bold text-indigo-600">{journalSummary.gratitude}</p>
              <p className="text-gray-600">Gratitudes</p>
            </div>
            <div className="journal-summary-item">
              <p className="text-2xl font-bold text-indigo-600">{journalSummary.happenings}</p>
              <p className="text-gray-600">Happenings</p>
            </div>
            <div className="journal-summary-item">
              <p className="text-2xl font-bold text-indigo-600">{journalSummary.intentions}</p>
              <p className="text-gray-600">Intentions</p>
            </div>
            <div className="journal-summary-item">
              <p className="text-2xl font-bold text-indigo-600">{journalSummary.todo}</p>
              <p className="text-gray-600">To Do</p>
            </div>
          </div>
        </Link>
        <div className="bg-white shadow-md rounded-lg p-6 flex flex-col justify-center items-center">
          <h2 className="text-xl font-semibold mb-4">Your Zen Level</h2>
          <div className="flex items-center mb-4">
            <img 
              src={getLevelIcon(totalPoints)} 
              alt={`Level ${Math.floor(totalPoints / 100) + 1} icon`} 
              className="w-12 h-12 mr-4"
            />
            <p className="text-3xl font-bold text-indigo-600">{Math.floor(totalPoints / 100) + 1}</p>
          </div>
          <CourseProgress progress={calculateProgressToNextLevel(totalPoints)} />
          <p className="text-gray-600 mt-4">Total Points: {totalPoints}</p>
          <p className="text-gray-600">Keep learning to level up!</p>
        </div>
        <div className="bg-white shadow-md rounded-lg p-6 flex flex-col justify-center">
          {quote && (
            <>
              <p className="text-2xl italic mb-4 font-serif leading-relaxed">&ldquo;{quote.quote}&rdquo;</p>
              <p className="text-right text-gray-600 text-lg">— {quote.author}</p>
            </>
          )}
        </div>
      </div>
      
      <div className="mb-8">
        <div className="flex items-center mb-4">
          <h2 className="text-2xl font-semibold mr-2">Recent Courses</h2>
          <Link to="/dashboard/courses" className="text-3xl text-indigo-600 hover:text-indigo-800">⊕</Link>
        </div>
        <div className="flex overflow-x-auto pb-4 space-x-4">
          {recentCourses.length > 0 ? (
            recentCourses.map((course, index) => (
              <div 
                key={index} 
                className="flex-none w-64 bg-white shadow-md rounded-lg overflow-hidden cursor-pointer transition-transform hover:scale-105"
              >
                <Link to={`/dashboard/courses/${course.id}`}>
                  <div className="relative pb-[100%]">
                    <img 
                      src={course.cover_image_url} 
                      alt={`${course.title} cover`} 
                      className="absolute top-0 left-0 w-full h-full object-cover"
                    />
                  </div>
                  <div className="p-4">
                    <h3 className="font-semibold text-lg mb-1 truncate">{course.title}</h3>
                    <p className="text-gray-600 text-sm truncate">Last studied: {new Date(course.lastCompletedAt).toLocaleDateString()}</p>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <p className="text-gray-600">No recent courses. Start learning!</p>
          )}
        </div>
      </div>

      <div className="mb-8">
        <div className="flex items-center mb-4">
          <h2 className="text-2xl font-semibold mr-2">Recent Chats</h2>
          <Link to="/chat" className="text-3xl text-indigo-600 hover:text-indigo-800">⊕</Link>
        </div>
        <div className="flex overflow-x-auto pb-4 space-x-4">
          {activities.length > 0 ? (
            activities.slice(0, 5).map((activity, index) => {
              const mindName = activity.details.split(' with ')[1];
              const mindId = mindName.replace(' ', '-');
              const mindIcon = require(`../components/mind-icons/${mindId}.png`);
              return (
                <div key={index} className="flex-none w-64">
                  <Link 
                    to={`/chat/${mindId.toLowerCase()}`} 
                    className="block w-full h-full bg-white shadow-md rounded-lg p-4 text-center relative overflow-hidden transition-transform hover:scale-105"
                  >
                    <img src={mindIcon} alt={`${mindName} icon`} className="w-24 h-24 mx-auto mb-2 rounded-full" />
                    <p className="text-sm font-medium">{mindName}</p>
                  </Link>
                </div>
              );
            })
          ) : (
            <p className="text-gray-600">No recent chats. Start a new conversation!</p>
          )}
        </div>
      </div>
      
      <div className="mb-8">
        <div className="flex items-center mb-4">
          <h2 className="text-2xl font-semibold mr-2">Recently Played Tracks</h2>
          <Link to="/streaming" className="text-3xl text-indigo-600 hover:text-indigo-800">⊕</Link>
        </div>
        <div className="flex overflow-x-auto pb-4 space-x-4">
          {recentTracks.length > 0 ? (
            recentTracks.map((track, index) => (
              <div 
                key={index} 
                className="flex-none w-64 bg-white shadow-md rounded-lg overflow-hidden cursor-pointer transition-transform hover:scale-105"
                onClick={() => playPause(track)}
                onMouseEnter={() => setHoveredTrack(track.id)}
                onMouseLeave={() => setHoveredTrack(null)}
              >
                <div className="relative">
                  <img src={track.artwork_url} alt={`${track.album} cover`} className="w-full h-40 object-cover" />
                  {hoveredTrack === track.id && (
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                      <button className="text-white text-4xl">▶</button>
                    </div>
                  )}
                </div>
                <div className="p-4">
                  <h3 className="font-semibold text-lg mb-1 truncate">{track.title}</h3>
                  <p className="text-gray-600 text-sm truncate">{track.artist}</p>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-600">No recently played tracks.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default DashboardHome;
