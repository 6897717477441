import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchCourseById, updateCourse, deleteModule, deleteLesson, uploadImage } from '../supabaseClient';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { ChevronDown, ChevronUp, Pencil, X, Plus, Upload, ArrowLeft } from 'lucide-react';

function CourseEditView() {
  const { courseId } = useParams();
  const [course, setCourse] = useState(null);
  const [editMode, setEditMode] = useState({});
  const [expandedModules, setExpandedModules] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchCourse();
  }, [courseId]);

  const fetchCourse = async () => {
    if (!courseId) {
      setError("Course ID is missing");
      setLoading(false);
      return;
    }
    try {
      setLoading(true);
      const courseData = await fetchCourseById(courseId);
      if (!courseData) {
        setError("Course not found");
      } else {
        setCourse(courseData);
        const initialExpandedState = {};
        courseData.modules.forEach(module => {
          initialExpandedState[module.id] = true;
        });
        setExpandedModules(initialExpandedState);
      }
    } catch (error) {
      console.error("Error fetching course:", error);
      setError("Failed to load course. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (field) => {
    setEditMode({ ...editMode, [field]: true });
  };

  const handleSave = async (field, value) => {
    try {
      const updatedCourse = { ...course, [field]: value };
      await updateCourse(courseId, updatedCourse);
      setCourse(updatedCourse);
      setEditMode({ ...editMode, [field]: false });
    } catch (error) {
      console.error("Error saving course:", error);
      setError("Failed to save changes. Please try again.");
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const { path } = await uploadImage(file, 'course-images');
        const updatedCourse = { ...course, cover_image_url: path };
        await updateCourse(courseId, updatedCourse);
        setCourse(updatedCourse);
      } catch (error) {
        console.error('Error uploading image:', error);
        setError("Failed to upload image. Please try again.");
      }
    }
  };

  const toggleModule = (moduleId) => {
    setExpandedModules({ ...expandedModules, [moduleId]: !expandedModules[moduleId] });
  };

  const handleDeleteModule = async (moduleId) => {
    try {
      await deleteModule(moduleId);
      fetchCourse();
    } catch (error) {
      console.error("Error deleting module:", error);
      setError("Failed to delete module. Please try again.");
    }
  };

  const handleDeleteLesson = async (lessonId) => {
    try {
      await deleteLesson(lessonId);
      fetchCourse();
    } catch (error) {
      console.error("Error deleting lesson:", error);
      setError("Failed to delete lesson. Please try again.");
    }
  };

  const handleBackToCourses = () => {
    navigate('/admin');
  };

  const handleAddModule = () => {
    navigate(`/admin/course/${courseId}/module/new`);
  };

  const handleAddLesson = (moduleId) => {
    navigate(`/admin/course/${courseId}/module/${moduleId}/lesson/new`);
  };

  if (loading) {
    return <div className="container mx-auto px-4 py-8">Loading...</div>;
  }

  if (error) {
    return <div className="container mx-auto px-4 py-8">Error: {error}</div>;
  }

  if (!course) {
    return <div className="container mx-auto px-4 py-8">Course not found</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Button onClick={handleBackToCourses} className="mb-4">
        <ArrowLeft className="mr-2 h-4 w-4" /> Back to Courses
      </Button>
      <div className="flex space-x-4">
        <Card className="w-1/3">
          <CardHeader>
            <CardTitle>Edit Course</CardTitle>
          </CardHeader>
          <CardContent>
            <div 
              className="w-full aspect-square rounded-md overflow-hidden cursor-pointer mb-4"
              onClick={handleImageClick}
            >
              {course.cover_image_url ? (
                <img src={course.cover_image_url} alt="Course cover" className="w-full h-full object-cover" />
              ) : (
                <div className="w-full h-full flex items-center justify-center bg-gray-200">
                  <Upload className="w-8 h-8 text-gray-400" />
                </div>
              )}
            </div>
            <input 
              type="file" 
              ref={fileInputRef} 
              onChange={handleImageUpload} 
              className="hidden" 
              accept="image/*"
            />
            <div className="mb-4">
              {editMode.title ? (
                <Input
                  value={course.title}
                  onChange={(e) => setCourse({ ...course, title: e.target.value })}
                  onBlur={() => handleSave('title', course.title)}
                />
              ) : (
                <div className="flex justify-between items-center">
                  <span className="font-bold">{course.title}</span>
                  <Button onClick={() => handleEdit('title')} variant="ghost" size="sm">
                    <Pencil className="h-4 w-4" />
                  </Button>
                </div>
              )}
            </div>
            <div>
              {editMode.description ? (
                <Textarea
                  value={course.description}
                  onChange={(e) => setCourse({ ...course, description: e.target.value })}
                  onBlur={() => handleSave('description', course.description)}
                />
              ) : (
                <div className="flex justify-between items-center">
                  <span>{course.description}</span>
                  <Button onClick={() => handleEdit('description')} variant="ghost" size="sm">
                    <Pencil className="h-4 w-4" />
                  </Button>
                </div>
              )}
            </div>
          </CardContent>
        </Card>
        <Card className="w-2/3">
          <CardHeader className="flex flex-row justify-between items-center">
            <CardTitle>Modules and Lessons</CardTitle>
            <Button onClick={handleAddModule} className="bg-blue-500 text-white">
              <Plus className="mr-2 h-4 w-4" /> Add Module
            </Button>
          </CardHeader>
          <CardContent>
            {course.modules.map((module) => (
              <div key={module.id} className="mb-4">
                <div className="flex justify-between items-center bg-gray-100 p-2 rounded">
                  <div className="flex items-center">
                    <Button onClick={() => toggleModule(module.id)} variant="ghost" size="sm">
                      {expandedModules[module.id] ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
                    </Button>
                    <span className="font-bold">{module.title}</span>
                  </div>
                  <div>
                    <Button onClick={() => navigate(`/admin/course/${courseId}/module/${module.id}`)} variant="ghost" size="sm">
                      <Pencil className="h-4 w-4" />
                    </Button>
                    <Button onClick={() => handleDeleteModule(module.id)} variant="ghost" size="sm">
                      <X className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
                {expandedModules[module.id] && (
                  <div className="ml-8 mt-2">
                    {module.lessons.map((lesson) => (
                      <div key={lesson.id} className="flex justify-between items-center mb-2">
                        <span>{lesson.title}</span>
                        <div>
                          <Button onClick={() => navigate(`/admin/course/${courseId}/module/${module.id}/lesson/${lesson.id}`)} variant="ghost" size="sm">
                            <Pencil className="h-4 w-4" />
                          </Button>
                          <Button onClick={() => handleDeleteLesson(lesson.id)} variant="ghost" size="sm">
                            <X className="h-4 w-4" />
                          </Button>
                        </div>
                      </div>
                    ))}
                    <Button onClick={() => handleAddLesson(module.id)} variant="outline" size="sm" className="mt-2">
                      <Plus className="mr-2 h-4 w-4" /> Add Lesson
                    </Button>
                  </div>
                )}
              </div>
            ))}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default CourseEditView;
