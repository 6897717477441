import React, { createContext, useState, useContext, useEffect } from 'react';
import { supabase } from './supabaseClient';
import { getUser } from './auth';

const PlaylistContext = createContext();

export const PlaylistProvider = ({ children }) => {
  const [playlists, setPlaylists] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await getUser();
      setUser(currentUser);
    };
    fetchUser();
  }, []);

  useEffect(() => {
    if (user) {
      fetchPlaylists();
    }
  }, [user]);

  const fetchPlaylists = async () => {
    if (!user) return;
    const { data, error } = await supabase
      .from('playlists')
      .select('*')
      .eq('user_id', user.id)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching playlists:', error);
    } else {
      setPlaylists(data);
    }
  };

  const createPlaylist = async (title, description, emoji) => {
    if (!user) throw new Error('User must be logged in to create a playlist');
    const { data, error } = await supabase
      .from('playlists')
      .insert([{ 
        user_id: user.id, 
        title, 
        description, 
        emoji,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }])
      .single();

    if (error) throw error;
    setPlaylists(prevPlaylists => [data, ...prevPlaylists]);
    return data;
  };

  const updatePlaylist = async (playlistId, updates) => {
    if (!user) throw new Error('User must be logged in to update a playlist');
    const { data, error } = await supabase
      .from('playlists')
      .update({
        ...updates,
        updated_at: new Date().toISOString()
      })
      .eq('id', playlistId)
      .eq('user_id', user.id)
      .single();

    if (error) throw error;
    setPlaylists(playlists.map(p => p.id === playlistId ? data : p));
    return data;
  };

  const deletePlaylist = async (playlistId) => {
    if (!user) throw new Error('User must be logged in to delete a playlist');
    const { error } = await supabase
      .from('playlists')
      .delete()
      .eq('id', playlistId)
      .eq('user_id', user.id);

    if (error) throw error;
    setPlaylists(prevPlaylists => prevPlaylists.filter(p => p.id !== playlistId));
  };

  const addTrackToPlaylist = async (playlistId, trackId) => {
    if (!user) throw new Error('User must be logged in to add a track to a playlist');
    const { data, error } = await supabase
      .from('playlist_tracks')
      .insert([{ 
        playlist_id: playlistId, 
        track_id: trackId, 
        user_id: user.id,
        added_at: new Date().toISOString(),
        position: await getNextPosition(playlistId)
      }])
      .single();

    if (error) throw error;
    movePlaylistToTop(playlistId);
    return data;
  };

  const removeTrackFromPlaylist = async (playlistId, trackId) => {
    if (!user) throw new Error('User must be logged in to remove a track from a playlist');
    const { error } = await supabase
      .from('playlist_tracks')
      .delete()
      .eq('playlist_id', playlistId)
      .eq('track_id', trackId)
      .eq('user_id', user.id);

    if (error) throw error;
    await fetchPlaylists();
  };

  const getNextPosition = async (playlistId) => {
    const { data, error } = await supabase
      .from('playlist_tracks')
      .select('position')
      .eq('playlist_id', playlistId)
      .order('position', { ascending: false })
      .limit(1);

    if (error) throw error;
    return data.length > 0 ? data[0].position + 1 : 0;
  };

  const movePlaylistToTop = (playlistId) => {
    setPlaylists(prevPlaylists => {
      const playlist = prevPlaylists.find(p => p.id === playlistId);
      const otherPlaylists = prevPlaylists.filter(p => p.id !== playlistId);
      return [playlist, ...otherPlaylists];
    });
  };

  return (
    <PlaylistContext.Provider value={{
      playlists,
      createPlaylist,
      updatePlaylist,
      deletePlaylist,
      addTrackToPlaylist,
      removeTrackFromPlaylist,
      fetchPlaylists,
    }}>
      {children}
    </PlaylistContext.Provider>
  );
};

export const usePlaylist = () => useContext(PlaylistContext);