import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { fetchCourses } from '../supabaseClient';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";

function Courses() {
  const [courses, setCourses] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const loadCourses = async () => {
      try {
        const coursesData = await fetchCourses();
        const categorizedCourses = coursesData.reduce((acc, course) => {
          if (!acc[course.category]) {
            acc[course.category] = [];
          }
          acc[course.category].push(course);
          return acc;
        }, {});
        setCourses(categorizedCourses);
      } catch (error) {
        console.error('Error loading courses:', error);
      } finally {
        setLoading(false);
      }
    };

    loadCourses();
  }, []);

  const handleCourseClick = (courseId) => {
    navigate(`/dashboard/courses/${courseId}`);
  };

  if (loading) {
    return <div>Loading courses...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Courses</h1>
      {Object.entries(courses).map(([category, categoryCourses]) => (
        <div key={category} className="mb-8">
        
          <div className="flex overflow-x-auto pb-4 space-x-4">
            {categoryCourses.map(course => (
              <Card 
                key={course.id} 
                className="flex-none w-64 cursor-pointer transition-transform duration-300 hover:scale-105"
                onClick={() => handleCourseClick(course.id)}
              >
                <img src={course.cover_image_url} alt={course.title} className="w-full h-48 object-cover rounded-t-lg" />
                <CardHeader>
                  <CardTitle className="truncate">{course.title}</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-sm text-gray-500 truncate">{course.description}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Courses;