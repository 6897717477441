import { createClient } from '@supabase/supabase-js'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY

export const supabase = createClient(supabaseUrl, supabaseAnonKey)

export const fetchCourses = async () => {
  const { data, error } = await supabase
    .from('courses')
    .select('*')
    .order('created_at', { ascending: false });
  if (error) throw error;
  return data;
};

export const fetchCourseById = async (id, userId) => {
  if (!id) {
    throw new Error("Course ID is required");
  }
  const { data: course, error: courseError } = await supabase
    .from('courses')
    .select(`
      *,
      modules (
        *,
        lessons (
          *,
          progress:user_progress(*)
        )
      )
    `)
    .eq('id', id)
    .single();

  if (courseError) {
    console.error("Error fetching course:", courseError);
    throw courseError;
  }

  if (!course) {
    return null;
  }

  // Filter progress to only include the current user's progress
  course.modules.forEach(module => {
    module.lessons.forEach(lesson => {
      lesson.progress = lesson.progress.find(p => p.user_id === userId) || null;
    });
  });

  return course;
};

export const fetchUserProgress = async (userId) => {
  const { data, error } = await supabase
    .from('user_progress')
    .select('*, lessons(course_id)')
    .eq('user_id', userId);
  
  if (error) throw error;
  
  return data.map(progress => ({
    ...progress,
    course_id: progress.lessons.course_id
  }));
};

export const updateUserProgress = async (userId, lessonId, completed) => {
  const { data, error } = await supabase
    .from('user_progress')
    .upsert(
      { 
        user_id: userId, 
        lesson_id: lessonId, 
        completed, 
        points: completed ? 10 : 0 
      }, 
      { onConflict: ['user_id', 'lesson_id'], ignoreDuplicates: false }
    );
  if (error) {
    console.error("Error updating user progress:", error);
    throw error;
  }
  return data;
};

export const fetchUserTotalPoints = async (userId) => {
  const { data, error } = await supabase
    .from('user_progress')
    .select('points')
    .eq('user_id', userId)
    .eq('completed', true);
  
  if (error) throw error;
  
  return data.reduce((total, item) => total + item.points, 0);
};

export const createCourse = async (courseData) => {
  const { data, error } = await supabase
    .from('courses')
    .insert(courseData)
    .select();
  if (error) {
    console.error("Error creating course:", error);
    throw new Error(`Error creating course: ${error.message}`);
  }
  return data[0];
};

export const updateCourse = async (courseId, courseData) => {
  const { title, description, cover_image_url } = courseData;
  const updates = { title, description };

  if (cover_image_url) {
    updates.cover_image_url = cover_image_url;
  }

  const { data, error } = await supabase
    .from('courses')
    .update(updates)
    .eq('id', courseId)
    .select();
  if (error) {
    console.error("Error updating course:", error);
    throw new Error(`Error updating course: ${error.message}`);
  }
  return data[0];
};

export const uploadImage = async (file, bucket = 'course-images') => {
  const fileExt = file.name.split('.').pop();
  const fileName = `${Math.random()}.${fileExt}`;
  try {
    const { data, error } = await supabase.storage
      .from(bucket)
      .upload(fileName, file);

    if (error) throw error;

    const { data: publicUrlData } = supabase.storage
      .from(bucket)
      .getPublicUrl(fileName);

    return { path: publicUrlData.publicUrl };
  } catch (error) {
    console.error("Error uploading image:", error);
    throw new Error(`Error uploading image: ${error.message}`);
  }
};

export const fetchModulesByCourseId = async (courseId) => {
  console.log("Fetching modules for course:", courseId);
  const { data, error } = await supabase
    .from('modules')
    .select('*')
    .eq('course_id', courseId)
    .order('order_index', { ascending: true });
  if (error) {
    console.error("Error fetching modules:", error);
    throw new Error(`Error fetching modules: ${error.message}`);
  }
  console.log("Fetched modules:", data);
  return data;
};

export const createModule = async (moduleData) => {
  console.log("Creating module with data:", moduleData);
  const { data, error } = await supabase
    .from('modules')
    .insert([moduleData])
    .select();

  if (error) {
    console.error("Error creating module:", error);
    throw error;
  }
  return data[0];
};

export const updateModule = async (id, moduleData) => {
  console.log("Updating module with id:", id, "and data:", moduleData);
  const { data, error } = await supabase
    .from('modules')
    .update(moduleData)
    .eq('id', id)
    .select();

  if (error) {
    console.error("Error updating module:", error);
    throw error;
  }
  return data[0];
};

export const deleteModule = async (moduleId) => {
  const { error } = await supabase
    .from('modules')
    .delete()
    .eq('id', moduleId);
  if (error) {
    console.error("Error deleting module:", error);
    throw new Error(`Error deleting module: ${error.message}`);
  }
};

export const fetchLessonsByModuleId = async (moduleId) => {
  console.log("Fetching lessons for module:", moduleId);
  const { data, error } = await supabase
    .from('lessons')
    .select('*')
    .eq('module_id', moduleId)
    .order('order_index', { ascending: true });
  if (error) {
    console.error("Error fetching lessons:", error);
    throw new Error(`Error fetching lessons: ${error.message}`);
  }
  console.log("Fetched lessons:", data);
  return data;
};

export const createLesson = async (lessonData) => {
  console.log("Creating lesson:", lessonData);
  const { data, error } = await supabase
    .from('lessons')
    .insert({
      title: lessonData.title,
      content: lessonData.content,
      module_id: lessonData.module_id,
      order_index: lessonData.order_index,
      content_type: lessonData.content_type || null,
      file_url: lessonData.file_url || null
    })
    .select();
  if (error) {
    console.error("Error creating lesson:", error);
    throw new Error(`Error creating lesson: ${error.message}`);
  }
  console.log("Created lesson:", data);
  return data[0];
};

export const updateLesson = async (lessonId, lessonData) => {
  console.log("Updating lesson:", lessonId, lessonData);
  const { data, error } = await supabase
    .from('lessons')
    .update({
      title: lessonData.title,
      content: lessonData.content,
      module_id: lessonData.module_id,
      order_index: lessonData.order_index,
      content_type: lessonData.content_type || null,
      file_url: lessonData.file_url || null
    })
    .eq('id', lessonId)
    .select();
  if (error) {
    console.error("Error updating lesson:", error);
    throw new Error(`Error updating lesson: ${error.message}`);
  }
  console.log("Updated lesson:", data);
  return data[0];
};

export const deleteLesson = async (lessonId) => {
  const { error } = await supabase
    .from('lessons')
    .delete()
    .eq('id', lessonId);
  if (error) {
    console.error("Error deleting lesson:", error);
    throw new Error(`Error deleting lesson: ${error.message}`);
  }
};

export const uploadLessonFile = async (file, onProgress) => {
  const fileExt = file.name.split('.').pop();
  const fileName = `${Math.random()}.${fileExt}`;
  try {
    const { data, error } = await supabase.storage
      .from('lesson-files')
      .upload(fileName, file, {
        cacheControl: '3600',
        upsert: false,
        onUploadProgress: (progress) => {
          if (onProgress) {
            onProgress(progress.percent);
          }
        },
      });

    if (error) throw error;

    const { data: publicUrlData, error: publicUrlError } = supabase.storage
      .from('lesson-files')
      .getPublicUrl(data.path);

    if (publicUrlError) throw publicUrlError;

    return publicUrlData.publicUrl;
  } catch (error) {
    console.error("Error uploading lesson file:", error);
    throw new Error(`Error uploading lesson file: ${error.message}`);
  }
};

export const fetchLessonFiles = async (lessonId) => {
  const { data, error } = await supabase
    .from('lessons')
    .select('file_url')
    .eq('id', lessonId)
    .single();
  if (error) {
    console.error("Error fetching lesson file:", error);
    throw new Error(`Error fetching lesson file: ${error.message}`);
  }
  return data.file_url;
};

// ... (keep all existing imports and functions)

export const resetMonthlyCredits = async () => {
  const { data, error } = await supabase.rpc('reset_monthly_credits');
  if (error) {
    console.error("Error resetting monthly credits:", error);
    throw error;
  }
  return data;
};

// ... (keep all other existing functions)