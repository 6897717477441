import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchCourseById, createCourse, updateCourse, uploadImage } from '../supabaseClient';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Upload, Plus } from 'lucide-react';

function CourseForm() {
  const [course, setCourse] = useState({
    title: '',
    description: '',
    cover_image: null,
    cover_image_url: null,
  });
  const [imagePreview, setImagePreview] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) fetchCourse();
  }, [id]);

  const fetchCourse = async () => {
    const courseData = await fetchCourseById(id);
    setCourse(courseData);
    setImagePreview(courseData.cover_image_url);
    console.log("Fetched course data:", courseData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let cover_image_url = course.cover_image_url;

    if (course.cover_image) {
      try {
        const { path } = await uploadImage(course.cover_image, 'course-images');
        cover_image_url = path;
      } catch (error) {
        console.error('Error uploading image:', error);
        return;
      }
    }

    const courseData = {
      title: course.title,
      description: course.description,
      cover_image_url: cover_image_url,
    };

    try {
      const data = id
        ? await updateCourse(id, courseData)
        : await createCourse(courseData);
      
      navigate('/admin');
    } catch (error) {
      console.error('Error creating/updating course:', error);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setCourse({ ...course, cover_image: file });
    setImagePreview(URL.createObjectURL(file));
  };

  const handleAddModule = () => {
    if (id) {
      navigate(`/admin/course/${id}/module/new`);
    } else {
      console.error("Cannot add module to unsaved course");
      // Optionally, show an error message to the user
    }
  };

  const handleAddLesson = () => {
    if (id) {
      navigate(`/admin/course/${id}/lesson/new`);
    } else {
      console.error("Cannot add lesson to unsaved course");
      // Optionally, show an error message to the user
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Card>
        <CardHeader>
          <CardTitle>{id ? 'Edit Course' : 'Add New Course'}</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <Input
              type="text"
              value={course.title}
              onChange={(e) => setCourse({ ...course, title: e.target.value })}
              placeholder="Course Title"
              required
            />
            <Textarea
              value={course.description}
              onChange={(e) => setCourse({ ...course, description: e.target.value })}
              placeholder="Course Description"
            />
            <div className="border-2 border-dashed border-gray-300 rounded-md p-4">
              {imagePreview ? (
                <div>
                  <img
                    src={imagePreview}
                    alt="Course Cover"
                    className="w-full h-32 object-cover rounded-md"
                  />
                  <Button
                    onClick={() => {
                      setCourse({ ...course, cover_image: null, cover_image_url: null });
                      setImagePreview(null);
                    }}
                    className="mt-2"
                  >
                    Remove Image
                  </Button>
                </div>
              ) : (
                <>
                  <input
                    type="file"
                    onChange={handleImageUpload}
                    accept="image/*"
                    className="hidden"
                    id="cover-image-upload"
                  />
                  <label htmlFor="cover-image-upload" className="cursor-pointer flex flex-col items-center">
                    <Upload className="w-8 h-8 text-gray-400" />
                    <span className="mt-2 text-sm text-gray-500">Upload Cover Image</span>
                  </label>
                </>
              )}
            </div>
            <Button type="submit" className="w-full">
              {id ? 'Update Course' : 'Create Course'}
            </Button>
          </form>
          {id && (
            <div className="mt-4 space-x-4">
              <Button onClick={handleAddLesson} variant="outline" className="border-blue-500 text-blue-500">
                <Plus className="mr-2 h-4 w-4" /> Add Lesson
              </Button>
              <Button onClick={handleAddModule} className="bg-blue-500 text-white">
                <Plus className="mr-2 h-4 w-4" /> Add Module
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default CourseForm;
