import React from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import SubscriptionButton from './SubscriptionButton';

function SubscriptionPage() {
  const navigate = useNavigate();

  const handleSubscriptionSuccess = async () => {
    // Refresh the user's subscription status
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data, error } = await supabase
        .from('subscriptions')
        .select('status')
        .eq('user_id', user.id)
        .single();

      if (data?.status === 'active') {
        navigate('/dashboard');
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Subscribe to DharmaLab
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Get access to all our premium features
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-medium text-gray-900">Premium Plan</h3>
              <p className="mt-1 text-sm text-gray-500">
                Full access to all features
              </p>
              <p className="mt-2 text-2xl font-semibold text-gray-900">$9.99/month</p>
            </div>
            <SubscriptionButton onSuccess={handleSubscriptionSuccess} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionPage;